import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import XModal from '../images/xModal.png';
import { AddButton } from '../util/CustomButtons';
import css from './GenericModal.module.css';
import { ConditionalTooltip } from '../util/ConditionalTooltip';

const useStyles = makeStyles({
  cancelButton: {
    textTransform: 'none',
  },
});

/**
 * Should you be using [Easy Modals] instead?
 *
 * It's better only to use this `<GenericModal>` for complex or dynamic child components.
 * A good example of a complex scenario is `<GenericOptionsModal>` which
 * could benefit from living in the React tree for rerender purposes e.g. an `options` list.
 *
 * But most of the time we've been `<GenericModal>` for simple boolean confirm dialogs with
 * mostly plain text. That has no need to clutter up your component tree.
 * In those cases it will be much easier to use `dialog` from `useAppContext`.
 * [Easy Modals] example:
 * ```jsx
 * function YourComponent(...) {
 *   ...
 *   const { dialog } = useAppContext();
 *
 *   ...
 *
 *   function handleSomeOnClick(...) {
 *     ...
 *     const answer = await dialog({
 *       title: "Are you sure?",
 *       text: "The quick brown fox jumps over the lazy dog.",
 *       //answersReturn: /** @ type {const} * /(["Yes", "No"]),
 *       answersReturn: (["Yes", "No"]) as const,
 *       cancelAsNull: "Cancel",
 *     });
 *     // answer will be "Yes" or "No" or null
 *     if (answer === "Yes") {
 *       ...
 *     }
 *   }
 * }
 * ```
 *
 * [Easy Modals]: https://notion.so/5ed3da4922124112b3a510da2e4a830c
 */
export function GenericModal({
  onClose,
  onOk,
  okLabel,
  cancelLabel,
  onCancel,
  open,
  title,
  children,
  disabledOk,
  showFooter = true,
  size = 'sm',
  subtitle,
  disabledOkTooltip = null,
}) {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" maxWidth={size} fullWidth open={open}>
      <div className={css.GenericModal}>
        <div>
          <div className={css.GenericModalHeader}>
            <div className={css.GenericModalTitle}>{title}</div>
            <IconButton
              aria-label="close"
              className={css.GenericModalHeaderCloseIcon}
              onClick={() => handleClose()}
            >
              <img src={XModal} alt="close" />
            </IconButton>
          </div>
          {subtitle && <div className={css.GenericModalSubtitle}> {subtitle} </div>}
        </div>
        <div className={css.GenericModalBody}>{children}</div>
        {showFooter && (
          <div className={css.GenericModalFooter}>
            <Button
              variant="outlined"
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
                handleClose();
              }}
              classes={{ root: classes.cancelButton }}
            >
              {cancelLabel || 'Cancel'}
            </Button>
            <ConditionalTooltip show={disabledOk && disabledOkTooltip} title={disabledOkTooltip}>
              <div>
                <AddButton handleSubmit={onOk} dataCy="modal-ok-button" disabledCondition={disabledOk}>
                  {okLabel}
                </AddButton>
              </div>
            </ConditionalTooltip>
          </div>
        )}
      </div>
    </Dialog>
  );
}
