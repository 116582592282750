import { css } from 'styled-components';
import theme from './theme';

export const LayoutMixin = css`
  width: 100%;
  margin: 0 auto;
  max-width: ${theme.breakpoints[375]};
  padding-left: ${theme.paddings.container.xs};
  padding-right: ${theme.paddings.container.xs};

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    max-width: ${theme.breakpoints[768]};
    padding-left: ${theme.paddings.container['xs+']};
    padding-right: ${theme.paddings.container['xs+']};
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    max-width: ${theme.breakpoints[834]};
    padding-left: ${theme.paddings.container.sm};
    padding-right: ${theme.paddings.container.sm};
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    max-width: ${theme.breakpoints[1024]};
    padding-left: ${theme.paddings.container['sm+']};
    padding-right: ${theme.paddings.container['sm+']};
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    max-width: ${theme.breakpoints[1194]};
    padding-left: ${theme.paddings.container.md};
    padding-right: ${theme.paddings.container.md};
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    max-width: ${theme.breakpoints[1440]};
    padding-left: ${theme.paddings.container.xl};
    padding-right: ${theme.paddings.container.xl};
  }
`;
export const ResetButton = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
`;
