import React from 'react';
import styled from 'styled-components';
import { LayoutMixin } from '../../../../theme';
import theme from '../../../../theme/theme';

const StyledWrapper = styled.div`
  background-color: ${theme.colors.Mindnight};
  overflow: hidden;
  margin-top: 72px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 100px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 140px;
  }
`;
const StyledInnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    ${LayoutMixin};
    flex-direction: row;
  }
`;
const StyledTextWrapper = styled.div`
  ${LayoutMixin};
  padding-top: 64px;
  padding-bottom: 64px;
  flex-shrink: 0;
  height: 100%;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    max-width: unset;
    margin: unset;
    padding: unset;
    box-sizing: content-box;
    padding-top: 120px;
    padding-bottom: 128px;
    width: 344px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    width: 388px;
    padding-bottom: 176px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-right: unset;
    width: 503px;
    padding-bottom: 120px;
  }
`;
function CommonTestimonial({ textChildren, children, className }) {
  return (
    <StyledWrapper className={className}>
      <StyledInnerWrapper>
        <StyledTextWrapper className="testimonial-text-wrapper">{textChildren}</StyledTextWrapper>
        {children}
      </StyledInnerWrapper>
    </StyledWrapper>
  );
}
export default CommonTestimonial;
