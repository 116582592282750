import { createContext, useContext, useMemo, useRef, type MutableRefObject } from 'react';
import { hasManagerPermissions } from '../../../common/permissions';
import { tsAssumeMemoized } from '../../../common/util/types/ts-assume';
import { _useMakeApiContext, type ApiContext } from '../../context/api-context';
import type { ManagerProfileContext } from '../../libraries/managerProfile';
import { _useMakeManagerProfileContextValue } from '../../libraries/managerProfile';
import type { GetTokenSilently } from '../../libraries/react-auth0-spa';
import { _useMakeSnackbarContext, type SnackbarContext } from '../../libraries/snackbar';
import type { MemoizedProps } from '../../util/react-memo-util';
import { useAppDialogFn, type DialogFn } from './AppDialogs';

export const _partialLoadIndRef = Symbol('_partialLoadIndRef');

export type AppContext = MemoizedProps<
  {
    isAuthenticatedAndIsManager: boolean;

    managerProfile: ManagerProfileContext;

    getTokenSilently: GetTokenSilently;

    api: ApiContext;

    /**
     * [Easy Modals](https://notion.so/5ed3da4922124112b3a510da2e4a830c)
     */
    dialog: DialogFn;

    /** for getting width and height of viewport */
    bottomrightRef: MutableRefObject<HTMLDivElement>;

    [_partialLoadIndRef]: MutableRefObject<undefined | { triggerTinyRerender: (random: {}) => void }>;
  } & {
    _ts_hint_memoized: true;
  } & SnackbarContext
>;

export const AppContext = createContext({} as AppContext);

export function useAppContext() {
  return useContext(AppContext);
}

export function _useMakeAppContext() {
  // getting getTokenSilently from _useMakeManagerProfileContextValue() so we don't have to useAuth0() twice
  const { managerProfile, getTokenSilently, isAuthenticated, user } = _useMakeManagerProfileContextValue();
  const isAuthenticatedAndIsManager = isAuthenticated && hasManagerPermissions(user);

  const api = _useMakeApiContext(getTokenSilently);

  const { snackbarCtxVal, snackbarsImplementationRef } = _useMakeSnackbarContext();
  const { dialog, dialogFnRef } = useAppDialogFn();

  const bottomrightRef = useRef<HTMLDivElement>(null);

  const partialLoadIndRef = useRef();

  const context: AppContext = tsAssumeMemoized(
    useMemo(
      () => ({
        isAuthenticatedAndIsManager,
        managerProfile,
        getTokenSilently,
        api,
        ...snackbarCtxVal,
        dialog,
        bottomrightRef,
        [_partialLoadIndRef]: partialLoadIndRef,
      }),
      [
        isAuthenticatedAndIsManager,
        managerProfile,
        getTokenSilently,
        api,
        snackbarCtxVal,
        dialog,
        bottomrightRef,
        partialLoadIndRef,
      ],
    ),
  );

  return { context, snackbarsImplementationRef, dialogFnRef, bottomrightRef };
}
