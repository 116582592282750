import { createContext, useContext } from 'react';

const WhichPortalContext = createContext<{ isVendorPortal: boolean; isOwnerPortal: boolean } | undefined>(
  undefined,
);

export const WhichPortalContextProvider = WhichPortalContext.Provider;

export default function useWhichPortal() {
  const context = useContext(WhichPortalContext);
  if (!context) throw new Error('useWhichPortal invoked outside of WhichPortalContext.Provider');
  return context;
}
