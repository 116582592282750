import React from 'react';
import { forwardRef, memo } from 'react';
const CalculatorIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M53.136 10H15.864c-1.03 0-1.864.834-1.864 1.864v52.182c0 1.029.834 1.863 1.864 1.863h37.272c1.03 0 1.864-.834 1.864-1.864V11.865c0-1.03-.834-1.864-1.864-1.864Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.716 59.387a1.398 1.398 0 1 0 0-2.796 1.398 1.398 0 0 0 0 2.796ZM34.5 59.387a1.398 1.398 0 1 0 0-2.796 1.398 1.398 0 0 0 0 2.796Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.284 59.387a1.398 1.398 0 1 0 0-2.796 1.398 1.398 0 0 0 0 2.796Z"
      fill="#F25E53"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.716 49.136a1.398 1.398 0 1 0 0-2.795 1.398 1.398 0 0 0 0 2.795ZM34.5 49.136a1.398 1.398 0 1 0 0-2.795 1.398 1.398 0 0 0 0 2.795ZM44.284 49.136a1.398 1.398 0 1 0 0-2.795 1.398 1.398 0 0 0 0 2.795ZM24.716 38.886a1.398 1.398 0 1 0 0-2.795 1.398 1.398 0 0 0 0 2.795ZM34.5 38.886a1.398 1.398 0 1 0 0-2.795 1.398 1.398 0 0 0 0 2.795ZM44.284 38.886a1.398 1.398 0 1 0 0-2.795 1.398 1.398 0 0 0 0 2.795ZM19.59 16.591a1 1 0 0 1 1-1H48.41a1 1 0 0 1 1 1v11.046a1 1 0 0 1-1 1H20.591a1 1 0 0 1-1-1V16.59Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.818 20v3.727"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={52.93} cy={55.93} r={13.93} fill="#F25E53" />
    <path
      d="M55.935 53.083a3.04 3.04 0 0 0-1.018-1.806 2.968 2.968 0 0 0-1.93-.716c-1.65 0-2.987 1.103-2.987 2.774s1.494 2.429 2.988 2.775c1.494.346 2.988 1.103 2.988 2.775 0 1.671-1.338 2.774-2.988 2.774a2.97 2.97 0 0 1-2.113-.886 3.047 3.047 0 0 1-.875-2.14M53.415 49.913V48m0 16.22v-1.914"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(CalculatorIcon);
const Memo = memo(ForwardRef);
export default Memo;
