import React from 'react';
const CloseIcon = () => (
  <svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 25 25 1M25 25 1 1"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CloseIcon;
