/**
 * @author Kuitos
 * @homepage https://github.com/kuitos/axios-extensions/blob/master/src/throttleAdapterEnhancer.ts
 * @since 2017-10-11
 */

import axios, { AxiosAdapter, AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import LRUCache from 'lru-cache';

export interface AxiosRequestConfigExtraProps {
  /** Set to true to disable throttling for this request */
  noThrottle?: boolean;
  /** e.g. if POST is used to send a GET request like in getShells */
  methodOverride?: Method;
}

/**
 * Extended AxiosRequestConfig. P = Params, BM = BackendModel, FM = FrontendModel
 */
export interface AxiosRequestConfigEx extends AxiosRequestConfig, AxiosRequestConfigExtraProps {}

export type ICacheLike<T> = {
  get(key: string): T | undefined;
  set(key: string, value: T): void;
} & ({ del(key: string): void } | { delete(key: string): void });

export type RecordedCache = {
  timestamp: number;
  inFlight: boolean;
  value?: AxiosPromise;
};

export type Options = {
  threshold?: number;
  thresholdMutating?: number;
  cache?: ICacheLike<RecordedCache>;
};

export default function throttleAdapterEnhancer(adapter: AxiosAdapter, options: Options = {}): AxiosAdapter {
  const {
    threshold = 1000,
    thresholdMutating = 500,
    cache = new LRUCache<string, RecordedCache>({ max: 10 }),
  } = options;

  const recordCacheWithRequest = (index: string, config: AxiosRequestConfig) => {
    const responsePromise = (async () => {
      try {
        const response = await adapter(config);

        cache.set(index, {
          timestamp: Date.now(),
          inFlight: false,
          value: Promise.resolve(response),
        });

        return response;
      } catch (reason) {
        // eslint-disable-next-line no-unused-expressions
        'delete' in cache ? cache.delete(index) : cache.del(index);
        throw reason;
      }
    })();

    cache.set(index, {
      timestamp: Date.now(),
      inFlight: true,
      value: responsePromise,
    });

    return responsePromise;
  };

  return (config: AxiosRequestConfigEx) => {
    const { url, params, paramsSerializer, data } = config;
    const method = config.methodOverride || config.method;

    const isMutating = method === 'post' || method === 'put' || method === 'delete';
    let maxAge;
    if (isMutating) {
      maxAge = thresholdMutating;
    } else if (method === 'get') {
      maxAge = threshold;
    } else {
      return adapter(config);
    }

    const t0 = Date.now();
    const dataString = JSON.stringify(data, Object.keys(data || {}).sort());
    const index = `${buildSortedURL(url, params, paramsSerializer)}|${method}|${dataString}`;
    const tDelta = Date.now() - t0;
    if (tDelta > 1000) {
      console.error(`throttleAdapterEnhancer: ${tDelta}ms to serialize ${method} ${url}: ${index.length}`);
    }

    const now = Date.now();
    const cachedRecord = cache.get(index) || { timestamp: now, value: undefined, inFlight: false };

    if (now - cachedRecord.timestamp <= maxAge || (isMutating && cachedRecord.inFlight)) {
      const responsePromise = cachedRecord.value;
      if (responsePromise) {
        /* istanbul ignore next */
        if (process.env.LOGGER_LEVEL === 'info') {
          console.info(`axios throttled request: ${index}`);
        }
        return responsePromise;
      }
    }

    return recordCacheWithRequest(index, config);
  };
}

export function buildSortedURL(...args: any[]) {
  const [url, params, paramsSerializer] = args;
  const builtURL = axios.getUri({ url, params, paramsSerializer });

  const [urlPath, queryString] = builtURL.split('?');

  if (queryString) {
    const paramsPair = queryString.split('&');
    return `${urlPath}?${paramsPair.sort().join('&')}`;
  }

  return builtURL;
}
