import React from 'react';
const LinkedinIcon = () => (
  <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.12594 10.0339H7.39379V28.9821H1.12594V10.0339ZM23.5697 9.40709C20.8215 9.40709 19.1822 10.4196 17.4465 12.6375V10.0339H11.1786V28.9821H17.4947V18.7125C17.4947 16.5428 18.6036 14.4214 21.1108 14.4214C23.6179 14.4214 25.1125 16.5428 25.1125 18.6642V28.9821H31.4286V18.2303C31.4286 10.7571 26.3661 9.40709 23.5697 9.40709ZM4.28397 0.824951C2.21076 0.824951 0.571472 2.31959 0.571472 4.15174C0.571472 5.98388 2.21076 7.47852 4.28397 7.47852C6.35719 7.47852 7.99647 5.98388 7.99647 4.15174C7.99647 2.31959 6.35719 0.824951 4.28397 0.824951Z"
      fill="white"
    />
  </svg>
);
export default LinkedinIcon;
