import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from '../../../theme/theme';

export const LinkButtonMixin = css`
  font-weight: 300;
  font-family: ${theme.fontFamily.Inter};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  font-style: normal;
  font-weight: ${(p) => (p.$small && '700') || (p.$big && '400px')};
  font-size: ${(p) => (p.$small && '16px') || (p.$big && '24px')};
  line-height: ${(p) => (p.$small && '19px') || (p.$big && '40px')};
  color: ${(p) => (p.disabled ? `${theme.colors['Paper White']}` : `${theme.colors['Moonlit Ocean']}`)};
  text-decoration: ${(p) => p.$underline && 'underline'};

  &:hover {
    color: ${(p) => (p.disabled ? `${theme.colors['Paper White']}` : `${theme.colors['Deep Sea Coral']}`)};
  }
  &:active {
    color: ${(p) =>
      p.disabled ? `${theme.colors['Paper White']}` : `${theme.colors['Deep Sea Coral Pressed']}`};
  }
`;
const StyledWrapper = styled(Link)`
  ${LinkButtonMixin}
`;
function ButtonLink({ disabled, children, small, big, underline, ...rest }) {
  return (
    <StyledWrapper disabled={disabled} $big={big} $small={small} $underline={underline} {...rest}>
      {children}
    </StyledWrapper>
  );
}
export default ButtonLink;
