import React from 'react';
import bmui from './css/util/bmui.module.css';
import Logo from './images/Banner-Logo.png';

const IS_CYPRESS = !!window.Cypress;

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false, error: [] };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: [error] };
  }

  componentDidCatch(error, errorInfo) {
    // @TODO Log error to error service here.
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return this.props.fallback ?? <DefaultErrorFallback errors={this.state.error} />;
  }
}

function DefaultErrorFallback({ errors }) {
  const widthStyles = {
    width: IS_CYPRESS ? 'auto' : '520px',
    maxWidth: '99%',
  };

  return (
    <div
      className={bmui.bmuiScope}
      style={{
        fontSize: '14px',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={Logo} alt="" style={{ width: '200px' }} />
      <div
        style={{
          marginTop: '30px',
          marginBottom: '15px',
          lineHeight: '30px',
          ...widthStyles,
          textAlign: 'left',
        }}
      >
        You've run into an <span style={{ color: 'red', fontWeight: '700' }}>error</span>. We apologize for
        this inconvenience. <br />
        Refresh the page, go back, or chat with our team below to report the bug.
      </div>

      <div
        style={{
          color: '#e74c3c',
          marginBottom: '30px',
          lineHeight: IS_CYPRESS ? '1.2' : '30px',
          ...widthStyles,
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          textAlign: IS_CYPRESS ? 'left' : 'inherit',
          fontSize: IS_CYPRESS ? '22px' : 'inherit', // larger font size to overcome scaling down of Cypress screenshots
        }}
      >
        {errors.map((e, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <div key={index}>{`${(IS_CYPRESS ? e.stack : e.message) || e}`}</div>;
        })}
      </div>

      <button id="chatWithUsButton" style={{ fontSize: '16px', padding: '8px 16px', cursor: 'pointer' }}>
        Chat With Us
      </button>
    </div>
  );
}
