import React from 'react';
import { forwardRef, memo } from 'react';
const ChevronDownIcon = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" ref={ref} {...props}>
    <path stroke="#F25E53" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m6 9 6 6 6-6" />
  </svg>
);
const ForwardRef = forwardRef(ChevronDownIcon);
const Memo = memo(ForwardRef);
export default Memo;
