/*********************************************************/
/* Banner scoped CSS overrides to material ui components */
/*********************************************************/
/*
    Usage: (1.) import bmui from '../../css/util/bmui.module.css';
           (2.) add className={bmui.bmuiScope} to some parent component
 */

.bmuiScope {
  white-space: pre-wrap; /* Ideally this would be :root default. */
}

.bmuiScope :global(.MuiOutlinedInput-notchedOutline) {
  border-color: var(--input-outline-color);
}

.bmuiScope :global(.MuiOutlinedInput-root:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline) {
  border-color: var(--input-outline-hover-color);
}

.bmuiScope :global(.MuiOutlinedInput-root:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline) {
  border-color: var(--primary);
}

.bmuiScope :global(.MuiOutlinedInput-adornedEnd) {
  padding-right: 0;
}

.bmuiScope :global(.MuiRadio-colorPrimary) {
  color: var(--primary);
}

.bmuiScope :global(.MuiSwitch-colorSecondary/* default */.Mui-checked),
.bmuiScope :global(.MuiCheckbox-colorSecondary/* default */):hover,
.bmuiScope :global(.MuiCheckbox-root.Mui-checked) {
  color: var(--primary);
}
.bmuiScope :global(.MuiSwitch-colorSecondary/* default */.Mui-checked + .MuiSwitch-track) {
  background-color: var(--primary);
}
.bmuiScope :global(.MuiSwitch-colorSecondary/* default */.Mui-checked:hover) {
  background-color: var(--primary-glow);
}
.bmuiScope :global(.MuiCheckbox-root.MuiIconButton-colorSecondary/* default */:hover) {
  background-color: rgba(20, 100, 130, 0.04);
}
.bmuiScope :global(.MuiCheckbox-root.Mui-disabled) {
  color: rgba(0, 0, 0, 0.26);
}

.bmuiScope input[readonly] {
  background: hsla(120, 0%, 80%, 0.3); /* TODO check style guide whether this is optimal */
}

.clickthroughPopover {
  pointer-events: none;
}
.clickthroughPopover :global(.MuiPopover-paper) {
  pointer-events: auto;
}
