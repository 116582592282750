import React from 'react';
import theme from '../../../../theme/theme';
import styled from 'styled-components';
import OurToolsSection from '../../Home/OurToolsSection';
import Footer from '../Footer';
const StyledFooterWrapper = styled.div`
  position: relative;
`;
const StyledFooter = styled(Footer)`
  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 187px;
    padding-top: 193px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    margin-top: 204px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 250px;
  }
`;
const OurToolsFooter = ({ className }) => {
  return (
    <StyledFooterWrapper className={className}>
      <OurToolsSection className="ourtools-section" />
      <StyledFooter className="ourtools-footer" />
    </StyledFooterWrapper>
  );
};
export default OurToolsFooter;
