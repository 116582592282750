import { every } from 'lodash';
import { useEffect } from 'react';
import { usePreventScrollContext } from '../contexts/contexts';

const usePreventScroll = ({ open, id }) => {
  const { preventing, handleSetPreventing } = usePreventScrollContext();

  useEffect(() => {
    const { body } = document;
    const scrollDistance = window.scrollY;
    if (open) {
      handleSetPreventing(id, true);
      body.style.position = 'fixed';
      body.style.top = `${scrollDistance * -1}px`;
    }
    return () => {
      if (!open) return;
      handleSetPreventing(id, false);
      if (!every(preventing, (v) => !v)) return;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, scrollDistance);
    };
  }, [open]);
  return { preventing };
};
export default usePreventScroll;
