import React from 'react';
import { forwardRef, memo } from 'react';
const MilestoneIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M54.906 28.656C55 27.906 55 27.25 55 26.5a22.5 22.5 0 1 0-45 0c0 .75 0 1.5.094 2.156v.282C11.969 46.75 32.5 59.313 32.5 59.313s20.531-12.75 22.313-30.282l.093-.375Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.079 31.898a1 1 0 0 0-1.158 0l-4.647 3.298c-.793.563-1.842-.223-1.524-1.142l1.771-5.127a1 1 0 0 0-.372-1.146l-4.544-3.175c-.802-.56-.406-1.82.572-1.82h5.657a1 1 0 0 0 .948-.681l1.77-5.278c.305-.91 1.591-.91 1.896 0l1.77 5.277a1 1 0 0 0 .948.683h5.657c.978 0 1.374 1.26.572 1.82l-4.544 3.174a1 1 0 0 0-.372 1.146l1.771 5.127c.318.92-.73 1.705-1.524 1.142l-4.647-3.298Z"
      fill="#F25E53"
    />
  </svg>
);
const ForwardRef = forwardRef(MilestoneIcon);
const Memo = memo(ForwardRef);
export default Memo;
