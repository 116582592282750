import React from 'react';
import { forwardRef, memo } from 'react';
const MutedIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={24}
    height={23}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m11.036 5.062-4.613 3.69H2.732v5.537h3.69l4.614 3.69V5.062Z"
      stroke="#fff"
      strokeWidth={2.076}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m16.002 9 4.96 4.96m0-4.96-4.96 4.96"
      stroke="#fff"
      strokeWidth={2.08}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(MutedIcon);
const Memo = memo(ForwardRef);
export default Memo;
