import { useEffect, useState } from 'react';
import { BREAKPOINTS } from '../theme';
import useGetWindowWidth from './useGetWindowWidth';

const useNavbar = () => {
  const { windowWidth } = useGetWindowWidth();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  useEffect(() => {
    if (windowWidth < BREAKPOINTS.xl) return;
    setIsNavbarOpen(false);
  }, [setIsNavbarOpen, windowWidth]);
  return { windowWidth, isNavbarOpen, setIsNavbarOpen };
};
export default useNavbar;
