import _ from 'lodash';
import type { ITag } from '../types/tag';

export function generateBuildingTags(building: any, allTags: ITag[]) {
  const buildingID = building._id.toString();
  const tagValueMap = building.additionalFields?.tags || {};
  const hasTagValue = Object.keys(tagValueMap).length > 0;

  const tags = [];
  const buildingTags = [];
  for (const tag of allTags) {
    const { buildingIDs = ['All'], shellIDs, projectIDs, teamID, ...rest } = tag;

    if (!(buildingIDs.includes('All') || buildingIDs.includes(buildingID))) {
      continue;
    }

    tags.push(rest);
    if (hasTagValue) {
      const tagID = tag._id.toString();
      const tagValue = tagValueMap[tagID];
      if (tagValue) {
        buildingTags.push({
          id: tagID,
          name: tag.name,
          value: tagValue,
          location: tag.location,
          dataType: tag.dataType,
          viewRoles: tag.viewRoles,
          editRoles: tag.editRoles,
          type: tag.type,
        });
      }
    }
  }

  return { tags, buildingTags };
}

/**
 * Sets building.tags prop to support legacy code
 */
export function setBuildingTags(building: any, allTags: ITag[]) {
  if (!building.tags) {
    // Set non-empty building tags only
    const nonEmptyTags = allTags.filter((t) => {
      const tagValue = building.additionalFields?.tags?.[t._id.toString()];
      return !_.isNil(tagValue) && tagValue !== '';
    });
    building.tags = generateBuildingTags(building, nonEmptyTags).tags;
  }
}
