import React from 'react';
import styled from 'styled-components';
import { Autoplay } from 'swiper';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';
import config from '../../../../config';
import { LayoutMixin } from '../../../../theme';
import theme, { BREAKPOINTS } from '../../../../theme/theme';
import CommonTestimonial from '../../common/CommonTestimonial';
import JonPic from '../../../../assets/jonPic.jpeg';
import DavePic from '../../../../assets/davePic.jpeg';
import JimPic from '../../../../assets/jimPic.jpeg';
import DorothyPic from '../../../../assets/dorothyPic.jpeg';
import TestimonialCard from './TestimonialCard';

const StyledH2 = styled.h2`
  color: white;

  > span {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: ${theme.colors['Deep Sea Coral']};
  }

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    line-height: 48px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    line-height: 56px;
  }
`;
const StyledQuotesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 640px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    height: 632px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    position: absolute;
    right: 0;
    height: 100%;
    width: 584px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    width: 730px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    width: 771px;
  }
`;
const StyledImage = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    padding-left: 37px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    padding-left: 63px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    padding-left: 59px;
  }
`;
const StyledSwiper = styled(SwiperReact)`
  ${LayoutMixin}
  width: 100%;
  height: 100%;

  .swiper-slide {
    height: auto;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    max-width: unset;
    margin: unset;
    padding: unset;
    position: absolute;
    padding-right: 40px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    padding-right: 53px;
  }
`;
const TESTIMONIALS = [
  {
    id: 1,
    src: `${DavePic}`,
    text: 'We looked at every solution on the market over a 2 year period and Banner was the only one willing to make critical customizations and go the extra mile.',
    author: 'Dave Ruth',
    company: 'Livcor',
  },
  {
    id: 2,
    src: `${JimPic}`,
    text: "Incredibly impressive, exactly what we needed. Super easy to use. It's great.",
    author: 'Jim Gentili',
    company: 'Industrious',
  },
  {
    id: 3,
    src: `${JonPic}`,
    text: 'I’m impressed with what you guys did, I think it’s going to provide a lot of benefits and I’m appreciative of it. I see it dealing with a lot of the things we’ve been struggling with over the years.',
    author: 'Jon',
    company: 'Tourmaline',
  },
  {
    id: 4,
    src: `${DorothyPic}`,
    text: 'We love this, we can just tell you what we want and then it just happens.',
    author: 'Dorothy Park',
    company: 'FCP',
  },
];
const SPACE_BETWEEN = 32;
const CUSTOM_TRANSITION_DURATION_MS = 2000;
const CUSTOM_AUTOPLAY_DELAY_MS = 2000;
const TOTAL_ANIMATION_DURATION = CUSTOM_TRANSITION_DURATION_MS + CUSTOM_AUTOPLAY_DELAY_MS;
let prevSwiperTranslate = 0;
const SwiperConfig = {
  slidesPerView: 'auto',
  spaceBetween: SPACE_BETWEEN,
  modules: [Autoplay],
  direction: 'vertical',
  centeredSlides: true,
  autoplay: {
    delay: TOTAL_ANIMATION_DURATION,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
    reverseDirection: true,
    waitForTransition: false,
  },
  loop: true,
  allowTouchMove: false,
  virtualTranslate: true,
  scrollbar: true,
  onTransitionStart: (swiper) => {
    const slidesQuantity = swiper.slides.length;
    const delayPerSlide = CUSTOM_AUTOPLAY_DELAY_MS / slidesQuantity;
    swiper.slides.forEach((s, i) => {
      const el = s;
      if (prevSwiperTranslate > swiper.translate) {
        el.style.transition = '';
        el.style.transitionDelay = '';
      } else {
        el.style.transition = `transform ${CUSTOM_TRANSITION_DURATION_MS}ms`;
        el.style.transitionDelay = `${delayPerSlide * (slidesQuantity - i)}ms`;
      }
      el.style.transform = `translateY(${swiper.translate}px)`;
    });
    prevSwiperTranslate = swiper.translate;
    setTimeout(() => {
      swiper.animating = false;
    }, 1);
  },
  breakpoints: {
    0: { spaceBetween: SPACE_BETWEEN * 2 },
    [BREAKPOINTS.xl]: { spaceBetween: SPACE_BETWEEN },
  },
};
function TestimonialsSection() {
  return (
    <CommonTestimonial
      textChildren={
        <StyledH2 data-aos="fade-up" data-aos-delay="200">
          Our tool is <span>highly configurable,</span> so our clients don’t have to change their processes as
          they move from spreadsheets/legacy systems to Banner.
        </StyledH2>
      }
    >
      <StyledQuotesWrapper>
        <StyledImage
          data-aos="fade-left"
          data-aos-delay="100"
          src={`${config.CLOUDFLARE_FULL_URL}/1025ae5d-c767-4879-237b-b9866c4c8d00/public`}
        />
        <StyledSwiper data-aos="fade-left" data-aos-delay="200" {...SwiperConfig}>
          {TESTIMONIALS.map((testimonial, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${testimonial.id}-${i}`}>
              <TestimonialCard {...testimonial} rtl={!((i + 1) % 2)} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </StyledQuotesWrapper>
    </CommonTestimonial>
  );
}
export default TestimonialsSection;
