import React from 'react';
import styled from 'styled-components';
import {
  CalculatorIcon,
  DashboardIcon,
  DatabaseIcon,
  MilestoneIcon,
  PricingIcon,
  QuoteIcon,
  RadarIcon,
  ReportIcon,
} from '../../../Icons';
import theme from '../../../../theme/theme';
import IconsGrid from '../../common/IconsGrid';
import FeatureCard from './FeatureCard';

const StyledIconsGrid = styled(IconsGrid)`
  margin-top: 74px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 100px;

    .inner-icons-grid {
      gap: 24px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    .inner-icons-grid {
      gap: 12px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 138px;

    .inner-icons-grid {
      gap: 24px;
    }
  }
`;
const FEATURES = [
  { id: 1, icon: <CalculatorIcon />, title: 'Budgeting' },
  { id: 2, icon: <RadarIcon />, title: 'Cost Tracking' },
  { id: 3, icon: <MilestoneIcon />, title: 'Milestones' },
  { id: 4, icon: <QuoteIcon />, title: 'Bidding' },
  { id: 5, icon: <PricingIcon />, title: 'Approvals' },
  { id: 6, icon: <ReportIcon />, title: 'Reporting' },
  { id: 7, icon: <DashboardIcon />, title: 'Cashflow Forecasting' },
  { id: 8, icon: <DatabaseIcon />, title: 'Vendor / Cost Database' },
];
function FeaturesSection() {
  return (
    <StyledIconsGrid tagline="Features" title="Banner is your one-stop shop for project delivery">
      {FEATURES.map((feature, i) => (
        <FeatureCard key={feature.id} data-aos="zoom-in" data-aos-delay={`${i * 50}`} {...feature} />
      ))}
    </StyledIconsGrid>
  );
}
export default FeaturesSection;
