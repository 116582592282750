import React from 'react';
import { forwardRef, memo } from 'react';
const RewindIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.577 22.594 3.197 14.52l10.38-8.073v16.147ZM26.264 22.594l-10.38-8.074 10.38-8.073v16.147Z"
      stroke="#fff"
      strokeWidth={2.768}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(RewindIcon);
const Memo = memo(ForwardRef);
export default Memo;
