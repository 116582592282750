import React from 'react';
import { forwardRef, memo } from 'react';
const VolumeIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={24}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m11.034 5.062-4.613 3.69h-3.69v5.537h3.69l4.613 3.69V5.062ZM18.481 4.997a9.227 9.227 0 0 1 0 13.047m-3.257-9.79a4.613 4.613 0 0 1 0 6.523"
      stroke="#fff"
      strokeWidth={2.076}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(VolumeIcon);
const Memo = memo(ForwardRef);
export default Memo;
