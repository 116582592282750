import React from 'react';
const MenuIcon = ({ className }) => (
  <svg
    width="28"
    height="20"
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M1 2H29" stroke="#F25E53" strokeWidth="1.75" strokeLinecap="round" />
    <path d="M1 10H23" stroke="#F25E53" strokeWidth="1.75" strokeLinecap="round" />
    <path d="M1 18H17" stroke="#F25E53" strokeWidth="1.75" strokeLinecap="round" />
  </svg>
);
export default MenuIcon;
