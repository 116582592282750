import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useScrollToTop from '../../../hooks/useScrollToTop';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { scrollToTop } = useScrollToTop({ behavior: 'auto' });
  useEffect(() => scrollToTop(), [scrollToTop, pathname]);
  return null;
}
