import React from 'react';
import { useAuth0 } from '../libraries/react-auth0-spa';
import Logo from '../components/images/Banner-Logo.png';
import css from '../components/css/error.module.css';

export default function LoginSaml() {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect({
    role: 'manager',
    action: 'login',
  });

  return render();
}

function render() {
  return (
    <div className={css.container}>
      <img className={css.loadingLogo} src={Logo} />
      <div className={css.errorBody}>
        <h3>Waiting for authentication response</h3>
      </div>
    </div>
  );
}
