import React, { useEffect, useState } from 'react';
import Button from '../Button';
import usePreventScroll from '../../../hooks/usePreventScroll';
import { GenericModal } from '../../../../components/plusComponents/GenericModal';

function HubSpotMeetingEmbed({ isModalOpen }) {
  useEffect(() => {
    if (isModalOpen) {
      const script = document.createElement('script');
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div
          className="meetings-iframe-container"
          data-src="https://meetings.hubspot.com/murph3/website-booking-form?embed=true"
        ></div>
      )}
    </>
  );
}

function BookADemoButton({ className, text = 'Book a Demo', ...rest }) {
  const [popupOpened, setPopupOpened] = useState(false);
  usePreventScroll({ id: 'bookADemoButton', open: popupOpened });
  return (
    <>
      <Button {...rest} className={className} onClick={() => setPopupOpened(true)}>
        {text}
      </Button>

      <GenericModal
        open={popupOpened}
        title="Book a Demo"
        showFooter={false}
        onClose={() => setPopupOpened(false)}
      >
        <HubSpotMeetingEmbed isModalOpen={popupOpened} />
      </GenericModal>
    </>
  );
}
export default BookADemoButton;
