import React from 'react';
import { DashboardBarIcon, DataUpdateIcon, MoneyIcon } from '../components/Icons';

export default [
  {
    id: 1,
    icon: <MoneyIcon />,
    title: 'Control Spend',
    description: 'Real time spend controls when they matter most',
  },
  {
    id: 2,
    icon: <DashboardBarIcon />,
    title: 'Single source of truth',
    description: 'Centralize all project data to one system',
  },
  {
    id: 3,
    icon: <DataUpdateIcon />,
    title: 'No manual data entry',
    description: 'Banner uses technology and integrations to pull key financial data',
  },
];
