import React from 'react';
import styled from 'styled-components';
import P from '../../../../common/P';
import { ParagraphVariants } from '../../../../common/P/P';
import theme from '../../../../../theme/theme';

const StyledWrapper = styled.div`
  height: 100%;
  min-height: 344px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 8px;
  overflow: hidden;
  background: ${theme.colors['Anti Flash White']};
  user-select: none;
`;
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
const StyledTitle = styled.h3`
  color: ${theme.colors['Moonlit Ocean']};
`;
const StyledDescription = styled(P)`
  color: ${theme.colors.Mindnight};
`;
function BenefitCard({ icon, title, description, id, ...rest }) {
  return (
    <StyledWrapper {...rest}>
      {icon}
      <StyledTextWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription variant={ParagraphVariants.SECONDARY}>{description}</StyledDescription>
      </StyledTextWrapper>
    </StyledWrapper>
  );
}
export default BenefitCard;
