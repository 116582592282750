import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../../theme/theme';
import { LayoutMixin } from '../../../theme';

const Wrapper = styled.div`
  background-color: ${theme.colors['Moonlit Ocean']};
  width: 100%;
  z-index: 99;
  overflow: hidden;
`;
const StyledTextWrapper = styled.div`
  ${LayoutMixin}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 16px 16px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    padding: 16px 0;
    flex-direction: row;
  }
`;
const StyledText = styled.h1`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-weight: 400;
  font-family: ${theme.fontFamily.Inter}, sans-serif;
  color: ${theme.colors.White};
  text-align: center;
  font-size: 14px;
  line-height: 16px;

  @media screen and (max-width: ${theme.breakpoints[375]}) {
    font-size: 10px;
  }
`;
const StyledLink = styled(Link)`
  :hover > span {
    color: ${theme.colors['Deep Sea Coral Hover']};
  }
`;
const StyledReadMoreText = styled.span`
  transition: color 0.2s;
  text-decoration: underline;
  font-weight: 700;
  color: ${theme.colors.White};
  text-align: center;
  font-size: 14px;
  line-height: 16px;

  @media screen and (max-width: ${theme.breakpoints[375]}) {
    font-size: 10px;
  }
`;
function Announcement({ className, ...rest }) {
  return (
    <Wrapper {...rest} className={className}>
      <StyledTextWrapper data-aos="fade-down">
        <StyledText>We just raised our $10M Series A, led by Blackstone.</StyledText>
        <StyledLink
          to={{ pathname: 'https://commercialobserver.com/2023/10/spend-management-banner/' }}
          target="_blank"
          rel="noreferrer"
        >
          <StyledReadMoreText>Read more -{'>'}</StyledReadMoreText>
        </StyledLink>
      </StyledTextWrapper>
    </Wrapper>
  );
}
export default Announcement;
