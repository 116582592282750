import React, { useEffect } from 'react';
import AOS from 'aos';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SopportWebpAvifProvider } from '../../../hooks/useSopportWebpAvif';
import ThemeProvider from '../../../theme/ThemeProvider';
import { PreventScrollProvider } from '../../../contexts/contexts';
import GlobalStyle from '../../../theme/globalStyle';
const queryClient = new QueryClient();
function RouteWrapper({ children }) {
  useEffect(() => {
    AOS.init({ once: true, duration: 600 });
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <SopportWebpAvifProvider>
        <PreventScrollProvider>
          <ThemeProvider>
            <GlobalStyle />
            {children}
          </ThemeProvider>
        </PreventScrollProvider>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </SopportWebpAvifProvider>
    </QueryClientProvider>
  );
}
export default RouteWrapper;
