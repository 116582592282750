import React from 'react';
import { forwardRef, memo } from 'react';
const RadarIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M32.5 27.7V3a28.5 28.5 0 0 1 26.6 18.24l-23.085 8.93" fill="#F25E53" />
    <path
      d="M32.5 35.3a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.7 32.64a15.2 15.2 0 1 1-22.8-14.345m36.005 10.83c.063.823.095 1.615.095 2.375A28.5 28.5 0 1 1 24.9 4.045"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(RadarIcon);
const Memo = memo(ForwardRef);
export default Memo;
