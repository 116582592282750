import React from 'react';
import { forwardRef, memo } from 'react';
const DataMoneyIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M34.2 27.061c12.813 0 23.2-3.595 23.2-8.03C57.4 14.596 47.013 11 34.2 11 21.387 11 11 14.595 11 19.03c0 4.436 10.387 8.031 23.2 8.031Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 19.03v37.478c0 4.461 10.35 8.03 23.2 8.03s23.2-3.569 23.2-8.03V19.03"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.4 31.523c0 4.462-10.35 8.03-23.2 8.03S11 35.986 11 31.524m46.4 12.492c0 4.462-10.35 8.031-23.2 8.031S11 48.476 11 44.016"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={54.93} cy={55.93} r={13.93} fill="#F25E53" />
    <path
      d="M57.935 53.083a3.04 3.04 0 0 0-1.018-1.806 2.968 2.968 0 0 0-1.93-.716c-1.65 0-2.987 1.103-2.987 2.774s1.494 2.429 2.988 2.775c1.494.346 2.988 1.103 2.988 2.775 0 1.671-1.338 2.774-2.988 2.774a2.97 2.97 0 0 1-2.113-.886 3.047 3.047 0 0 1-.875-2.14M55.415 49.913V48m0 16.22v-1.914"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(DataMoneyIcon);
const Memo = memo(ForwardRef);
export default Memo;
