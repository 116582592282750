import React from 'react';
import styled from 'styled-components';
import { SVGSocialMixin } from './SVGSocialLink';

const StyledButton = styled.button`
  ${SVGSocialMixin}
`;
function SVGSocialButton(props) {
  return <StyledButton {...props} />;
}
export default SVGSocialButton;
