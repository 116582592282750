import { Snackbar } from '@material-ui/core';
import MuiAlert, { type AlertProps } from '@material-ui/lab/Alert';
import { pick } from 'lodash';
import React, { useRef, useState, type ReactNode } from 'react';
import { type NotUndefined } from '../../common/util/type-utils';
import { useAppContext } from '../components/app-layout/AppContext';
import { subUseState, useGetset, type SetState } from '../util/react-utils';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type SnackbarContext = ReturnType<typeof _useMakeSnackbarContext>['snackbarCtxVal'];
export type SnackbarShowSuccess = SnackbarContext['showSuccess'];
export type SnackbarShowWarning = SnackbarContext['showWarning'];
export type SnackbarShowError = SnackbarContext['showError'];

type _SnackbarState = {
  message?: ReactNode;
  severity?: AlertProps['severity'];
  autoHideDuration?: number | null;
  open: boolean;
};

export function AppSnackbars(props: {
  snackbarsImplementationRef: ReturnType<typeof _useMakeSnackbarContext>['snackbarsImplementationRef'];
}) {
  const { snackbarsImplementationRef } = props;

  const snackbarState = useGetset(useState<_SnackbarState>({ open: false }));

  snackbarsImplementationRef.current = snackbarState.set;

  const [snackbarSeverity] = subUseState(snackbarState, 'severity');
  const [snackbarMessage] = subUseState(snackbarState, 'message');
  const [stateSnackbar, setStateSnackbar] = subUseState(snackbarState, 'open');
  const [snackbarAutoHideDuration] = subUseState(snackbarState, 'autoHideDuration');

  const handleCloseSnackbar = () => {
    setStateSnackbar(false);
  };

  return (
    <Snackbar
      open={stateSnackbar}
      autoHideDuration={snackbarAutoHideDuration}
      onClose={handleCloseSnackbar}
      ClickAwayListenerProps={{ mouseEvent: 'onMouseDown' }}
    >
      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
        <span data-cy="snackbar" style={{ width: '100%' }}>
          {snackbarMessage}
        </span>
      </Alert>
    </Snackbar>
  );
}

export function _useMakeSnackbarContext() {
  const snackbarsImplementationRef = useRef<null | SetState<_SnackbarState>>(null);

  const _show = (
    message: ReactNode,
    severity: NotUndefined<AlertProps['severity']>,
    autoHideDuration: number | null,
  ) => {
    if (snackbarsImplementationRef.current) {
      snackbarsImplementationRef.current({ message, severity, autoHideDuration, open: true });
    } else {
      // impossible race condition will never happen; but try alert just in case
      alert(message); // eslint-disable-line no-alert
    }
  };

  const showError = (message: ReactNode, autoHideDuration = null) => {
    _show(message, 'error', autoHideDuration);
  };

  const showSuccess = (message: ReactNode, autoHideDuration = 6000) => {
    _show(message, 'success', autoHideDuration);
  };

  const showWarning = (message: ReactNode, autoHideDuration = null) => {
    _show(message, 'warning', autoHideDuration);
  };

  const showInfo = (message: ReactNode, autoHideDuration = 6000) => {
    _show(message, 'info', autoHideDuration);
  };

  return {
    snackbarCtxVal: { showError, showSuccess, showWarning, showInfo },
    snackbarsImplementationRef,
  };
}

export function useSnackbar(): SnackbarContext {
  return pick(useAppContext(), ['showError', 'showSuccess', 'showWarning', 'showInfo']);
}
