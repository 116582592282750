//
// utility functions for developers not useful for production
//

import { useRef } from 'react';

/**
 * Writes to `console.log` every change to the deps to help diagnose `useMemo` / `useCallback` issues.
 *
 * @example
 * const makeColumnsInitialArgs: Parameters<typeof makeColumnsInitial> = [
 *   ...
 * ];
 * useLogEveryDepChange({ makeColumnsInitialArgs });
 * const columns = useMemo(() => {
 *   return makeColumnsInitial(...makeColumnsInitialArgs);
 * }, makeColumnsInitialArgs);
 */
export function useLogEveryDepChange(objOfDepsArrays: { [key: string]: any[] | Record<any, any> }) {
  if (process.env.NODE_ENV !== 'development') return;
  if (Array.isArray(objOfDepsArrays)) {
    throw new Error(
      'useLogEveryDepChange: objOfDepsArrays must be an object. Try useLogEveryDepChange({ someArray }) instead of useLogEveryDepChange(someArray).',
    );
  }

  const prevObj = useRef(objOfDepsArrays); // eslint-disable-line react-hooks/rules-of-hooks -- devModeOptions.useLogEveryDepChange is temporary and constant

  const detectedChanges = {};

  for (const [varName, deps] of Object.entries(objOfDepsArrays)) {
    const prevDeps = prevObj.current[varName] ?? {};
    for (const i in deps) {
      if (deps[i] !== prevDeps[i]) {
        const changesThisVar = detectedChanges[varName] ?? (detectedChanges[varName] = {});
        changesThisVar[i] = { before: prevDeps[i], after: deps[i] };
      }
    }
  }

  for (const [varName, changesThisVar] of Object.entries(detectedChanges)) {
    console.log('useLogEveryDepChange:', varName, changesThisVar);
  }

  prevObj.current = objOfDepsArrays;
}
