import axios from 'axios';
import { useEffect, useMemo, useReducer } from 'react';
import { getFeatureMapBasedOnManager } from '../../common/features';
import { hasManagerPermissions } from '../../common/permissions';
import type { ProfileController } from '../../server/controllers/profileController';
import { useAppContext } from '../components/app-layout/AppContext';
import type { SrcServerRes, SrcServerResJson } from '../context/api-context';
import { useAuth0 } from './react-auth0-spa';

//------------------------------------------------------------------------------

type ManagerProfileApiResponse = SrcServerRes<ProfileController['getManagerProfile']>;
type ManagerProfileApiResponse_NoDocFound = SrcServerResJson<null>;

//------------------------------------------------------------------------------

export type ManagerProfileApiObj = Exclude<ManagerProfileApiResponse, ManagerProfileApiResponse_NoDocFound>;

/**
 * Excludes {@link ManagerProfileContextLoadingOrError} because the vast majority of the app is inaccessible in this state.
 *
 * This saves us from adding `?.` to `managerProfile.profile` throughout the app to solve type errors.
 */
export type ManagerProfileContext = {
  logout: () => unknown;
  error: null | Error;
  isLoading: boolean;
  initProfile: (o?: { noThrottle?: boolean }) => Promise<void>;
  profile: ManagerProfileApiObj;
  teamName: string;
} & RoleFunctionResults & { _ts_hint_memoized: true };

export type ManagerProfileContextLoadingOrError = Pick<ManagerProfileContext, 'initProfile' | 'logout'> & {
  error: null | Error;
  isLoading: boolean;
  profile: null;
  teamName: null;
};

//------------------------------------------------------------------------------

type RoleFunctionResults = ReturnType<typeof getFeatureMapBasedOnManager>;

type Action =
  | { type: 'load::fail'; error: Error }
  | { type: 'load::ok'; profile: StateData['profile']; user: User };

type State = { stage: 'loading' } | { stage: 'error'; error: Error } | { stage: 'success'; data: StateData };

type StateData = {
  profile: ManagerProfileContext['profile'];
  teamName: string;
  roleFunctions: RoleFunctionResults;
};

//------------------------------------------------------------------------------

export function _useMakeManagerProfileContextValue() {
  const { getTokenSilently, isAuthenticated, loading, logout, user } = useAuth0();

  const defaultState: State = { stage: 'loading' };
  const [state, dispatch] = useReducer(reducer, defaultState);

  const initProfile = ({ noThrottle = true } = {}) =>
    fetchProfile(getTokenSilently, { noThrottle })
      .then((profile) => dispatch({ type: 'load::ok', profile, user }))
      .catch((error) => dispatch({ type: 'load::fail', error }));

  const defaultContext: ManagerProfileContextLoadingOrError = {
    initProfile,
    logout,
    error: null,
    isLoading: true,
    profile: null,
    teamName: null,
  };

  useEffect(() => {
    if (!loading && isAuthenticated && user) {
      initProfile({ noThrottle: false });
    }
  }, [loading, isAuthenticated, user]);

  const managerProfile = useMemo(
    () => contextFromState(defaultContext, state),
    [state],
  ) as ManagerProfileContext; // assuming as ManagerProfileContext because the vast majority of our code will be unreachable for ManagerProfileContextLoadingOrError

  return { managerProfile, getTokenSilently, isAuthenticated, user };
}

/**
 * You can use `useAppContext().managerProfile` instead. :)
 */
export function useManagerProfile() {
  return useAppContext().managerProfile;
}

//------------------------------------------------------------------------------

///
/// PRIVATE HELPER FUNCTIONS
///

/**
 * Generate the manager profile context from the Provider state.
 */
function contextFromState(
  defaultContext: ManagerProfileContextLoadingOrError,
  state: State,
): ManagerProfileContext | ManagerProfileContextLoadingOrError {
  switch (state.stage) {
    case 'success':
      return {
        ...defaultContext,
        ...state.data.roleFunctions,
        isLoading: false,
        profile: state.data.profile as ManagerProfileApiObj,
        teamName: state.data.teamName,
      };

    case 'error':
      return {
        ...defaultContext,
        error: state.error,
        isLoading: false,
      };

    case 'loading':
    default:
      return defaultContext;
  }
}

/**
 * Fetch the manager profile object from the API.
 */
async function fetchProfile(getToken: () => Promise<string>, { noThrottle }: { noThrottle: boolean }) {
  const token = await getToken();
  const res = await axios.get<ManagerProfileApiResponse>('/api/managers/getManagerProfile', {
    headers: { Authorization: `Bearer ${token}` },
    noThrottle,
  });

  return res.data;
}

/**
 * If heap.io is installed into the browser window, then we attach the
 * custom banner user identifier and required user properties.
 */
function heapSetup(auth0User: unknown, managerProfile: ManagerProfileApiResponse) {
  const { heap } = window as any;
  // Add user properties on login for heap.io tracking.
  // Heap API:
  // https://developers.heap.io/reference/client-side-apis-overview
  if (heap) {
    const idString = managerProfile._id ? managerProfile._id.toString() : 'UNKNOWN';

    heap.identify(`banner::${managerProfile.email}::${managerProfile.uID}::${idString}`);
    heap.addUserProperties({
      bannerUserID: managerProfile._id,
      company: managerProfile.companyName,
      email: managerProfile.email,
      name: managerProfile.name,
      teamID: managerProfile.currentTeam,
      teamName: managerProfile.team?.name,
      title: managerProfile.title,
      uID: managerProfile.uID,
      isManager: hasManagerPermissions(auth0User),
      isAdmin: managerProfile?.isAdmin,
    });
  }
}

/**
 * State reducer to handle profile loading events.
 */
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'load::ok':
      heapSetup(action.user, action.profile);
      sessionStorage.setItem('banner.currentTeamID', action.profile.currentTeam);
      sessionStorage.setItem('banner.lastUsedUID', action.profile.uID?.toString());

      return {
        ...state,
        data: {
          profile: action.profile,
          teamName: action.profile.team ? action.profile.team.name : null,
          roleFunctions: getFeatureMapBasedOnManager(action.profile),
        },
        stage: 'success',
      };

    case 'load::fail':
      console.error('Manager Profile :: load::fail ::', action.error);
      return {
        ...state,
        error: action.error,
        stage: 'error',
      };

    default:
      return state;
  }
}
