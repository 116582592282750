import config from '../config';
import FCPPic from '../assets/fcpPic.jpeg';
import TCPPic from '../assets/tourmalinePic.jpeg';
import IndustriousPic from '../assets/industriousPic.png';
import IndustriousWhite from '../assets/indWhite.png';

import TCP1 from '../assets/tcp1.jpeg';
import TCP2 from '../assets/tcp2.jpeg';
import TCP3 from '../assets/tcp3.jpeg';
import TCP4 from '../assets/tcp4.jpeg';

import IND1 from '../assets/ind1.jpeg';
import IND2 from '../assets/ind1.png';
import IND3 from '../assets/ind3.png';
import IND4 from '../assets/ind4.png';

export const CaseStudyCategories = {};

(() => {
  CaseStudyCategories.MFA = 'Multi-family';
  CaseStudyCategories.OFFICE = 'office';
  CaseStudyCategories.CO = 'Co-working';
})();

const caseStudies = [
  {
    id: 1,
    slug: 'fcp',
    image: `${FCPPic}`,
    category: CaseStudyCategories.MFA,
    title: 'FCP',
    description:
      'FCP® is a privately held national real estate investment company that invests directly and with operating partners in commercial and residential assets.',
    info: {
      text: 'FCP® is a privately held national real estate investment company that invests directly and with operating partners in commercial and residential assets.',
      logo: `${config.CLOUDFLARE_FULL_URL}/87646044-c97c-41ff-2291-956607d70b00/public`,
      challenge: {
        text: 'Managing a diverse portfolio with an emphasis on identifying properties and enhancing value to boost their NOI, FCP sought a more effective method for handling capital expenditure planning, execution, and monitoring. Senior Associate Dara Vaziri took the lead in looking for capex tracking solutions.\n\nOver a few years, Dara explored various internal and off-the-shelf third-party solutions. The resulting system still demanded substantial manual data entry and management to accurately direct invoices to the appropriate third-party property manager for approval. Moreover, the different solutions did not integrate smoothly. Dara continued searching for a solution capable of managing intricate tasks between internal teams and external property managers.',
        image: `${config.CLOUDFLARE_FULL_URL}/de70d5a1-e363-4fa4-e6f4-ee7f94f69d00/public`,
      },
      solution: {
        text: 'After two years, Dara was convinced that no existing market solution could tackle the full scope of FCPs challenges. As he contemplated the pros and cons of developing a custom-built solution, he discovered Banner. A new market player at the time, Banner impressed Dara with its automated invoice handling between FCP and their various property management partners. Following in-depth technical discussions and reference calls with existing Banner customers, FCP chose to partner with Banner, believing it would offer a single source of truth for analysis for FCP and all their partners. Adoption meant streamlined communication among stakeholders, linking managers, vendors, and asset managers through one system. It became evident that Banner could be the answer, getting everyone on the same page.',
        image: `${config.CLOUDFLARE_FULL_URL}/e9cb1dc6-2959-4317-81f2-864380d37c00/public`,
      },
      quote: {
        authorName: 'Dara Vaziri',
        authorJobPosition: 'SENIOR ANALYST',
        text: '“We implemented Banner because we can <span>manage our entire portfolio</span> on it and have access to every stakeholder involved, along with live information. It provides a really meaningful <span>check and balance</span> in our CapEx projects.”',
        image: `${config.CLOUDFLARE_FULL_URL}/c53febdb-24d2-4904-abed-fde76d124500/public`,
      },
      results: {
        text: 'Less than a year after adopting the platform, FCP now manages over 1,000 projects on Banner. Due to their unique holdings, delving into the intricate details of individual capital items is crucial for executing a successful CapEx business plan. Banner delivers a single source of truth for all projects, allowing every dollar spent to be analyzed and ensuring adherence to their business plan while making informed decisions for the future.\n\nVaziri notes, "Banner assists us in accurately allocating and attributing expenditures across our capital projects, giving us a clearer view of our spending and progress on our business plan." The outcome is substantial alignment and dependable data sets. Banner has granted FCP unobstructed visibility of its actual capital expenditures, leading to increased clarity in daily decisions and future budgeting. Mr. Vaziri considers this transparency to be the most significant added value since partnering with Banner, influencing both project prioritization and consensus on specific objectives each project aims to achieve.',
        image: `${config.CLOUDFLARE_FULL_URL}/f5334973-087e-439e-ba1f-c41096eac400/public`,
      },
    },
  },
  {
    id: 2,
    slug: 'tourmaline',
    image: `${TCP3}`,
    category: CaseStudyCategories.OFFICE,
    title: 'Tourmaline',
    description:
      'Tourmaline Capital partners focuses on opportunistic office investments throughout the continental United States. ',
    info: {
      text: 'Tourmaline Capital partners focuses on opportunistic office investments throughout the continental United States.',
      logo: `${config.CLOUDFLARE_FULL_URL}/95010867-e7c6-4523-c3f7-9a0826c34a00/public`,
      challenge: {
        text: 'Tourmaline was founded in 2021 and aggressively began ramping up their office value-add strategy, acquiring over $3B of CRE in their first year of existence. While this growth was their goal, they quickly outpaced their project management setup. Combining a widely used off-the-shelf solution with detailed spreadsheets they had developed from years of experience, they realized the solution would not scale as they continued their rapid growth.\n\nThey found themselves spending far too much time managing data entry, searching Excel for invoice data, hunting down relevant files, and creating construction management fee reports. They tried working with their off-the-shelf solution for ideas on scaling and found that route created more problems than it solved while eating up valuable time that could be spent on more productive efforts.',
        image: `${TCP1}`,
      },
      solution: {
        text: 'Tourmaline searched out Banner as a newer and more modern solution to these repetitive tasks that were burying them. From the initial conversations, it became clear that Banner’s level of automation and understanding of their business objectives would solve many of the problems they faced. \n\nThey recognized immediate benefits from automating invoice data ingestion using Banner’s optical character recognition (OCR) technology. Banner worked with them as a trusted partner to expand the core functionality of the platform to handle their complex spreadsheets. Banner created cash flow forecast tools based on Tourmaline’s specific logic, enhanced their construction management fee tool to handle the unique aspects of Tourmaline’s operations, and provided better budget management tools.\n\nA major driver in choosing Banner was the ability to run custom cash flow forecasting. Tourmaline has developed specific cash flow projection logic that has served their business well over time, but was prone to manual data entry issues. Banner set itself apart from the competition by allowing Tourmaline to retain its tailored cash flow logic, while automating the data entry process that was ripe for error.\n\nOverall, Tourmaline was able to get to their ideal solution within three months of working from Banner.',
        image: `${TCP2}`,
      },
      quote: {
        authorName: 'Jonathan Jacobs',
        authorJobPosition: 'Managing Principal, COO',
        text: '“If our prior software made 4 things easier, it made 4 things harder - Banner got us to <span>our ideal system</span>, delivering customization in spades”',
        image: `${TCP3}`,
      },
      results: {
        text: 'Almost immediately, Tourmaline was able to drastically reduce their data entry workload by over 80%. This also saw a reduction in data entry errors, which had been costly in the past, both in terms of their budget and their time involved in finding and correcting them. They found the time required to generate their construction management fee invoices was reduced by over 90%, giving senior staff more time to focus on finding, acquiring, and leasing their properties. \n\nOverall, automating and centralizing their project delivery processes with Banner has freed them up to continue to focus on growth. Tourmaline has continued to expand its portfolio and scaled its processes seamlessly through their continued partnership with Banner. They have the peace of mind that future growth will not be slowed down as Banner will continue to adapt to their lofty ambitions.',
        image: `${TCP4}`,
      },
    },
  },
  // {
  //   id: 3,
  //   slug: "tcp",
  //   image: `${config.CLOUDFLARE_FULL_URL}/51699594-9efa-4df4-81e7-5fd5f5ad7b00/public`,
  //   category: CaseStudyCategories.OFFICE,
  //   title: "TCP",
  //   description:
  //     "TCP prides itself on tech-forward thinking. They chose Banner to manage their 4mm sqft office portfolio.",
  //   info: {
  //     text: "FCP® is a privately held national real estate investment company that invests directly and with operating partners in commercial and residential assets.",
  //     logo: `${config.CLOUDFLARE_FULL_URL}/95010867-e7c6-4523-c3f7-9a0826c34a00/public`,
  //     challenge: {
  //       text: "Given the breadth of their holdings and a business strategy of identifying properties and adding value to increase their NOI, FCP was determined to \ntake a different approach to their capital expenditure planning, execution, \nand tracking.\n\nIn 2018, Dara Vaziri joined FCP as a Senior Analyst with two principle directives; implementing a new CAPEX management system across the FCP portfolio, and acting as a liaison between third-party property managers, vendors, asset and construction management.\n\nDara spent 2.5 years working towards a solution with various internal solutions and 3rd party off-the-shelf solutions. The solution that was developed still required a significant amount of manual data entry and data management to effectively and accurately route invoices to the correct 3rd party property manager for approvals. In addition, the various solutions did not communicate seamlessly with each other. Dara continued to look for a solution that could manage the complex tasks between their internal teams and their external property managers.",
  //       image: `${config.CLOUDFLARE_FULL_URL}/de70d5a1-e363-4fa4-e6f4-ee7f94f69d00/public`,
  //     },
  //     solution: {
  //       text: "Having spent two years, Dara was convinced that no solution available on the market at the time could handle the full ranges of challenges FCP faced. Dara started to weigh the pros and cons of building a custom-built solution when he came across Banner. \n\nA new entrant on market at the time, Banner caught his attention with their ability to automatically handle invoices between FCP and their various property management partners.  After extensive and detailed technical conversations and reference calls with Banner’s existing customers, FCP decided a partnership with Banner would provide a single source of truth for analysis for FCP and all of their partners. Adoption meant streamlining communication among stakeholders while connecting managers, vendors, and asset managers through one system. It became clear that this could be their solution, putting everyone on the same page.",
  //       image: `${config.CLOUDFLARE_FULL_URL}/e9cb1dc6-2959-4317-81f2-864380d37c00/public`,
  //     },
  //     quote: {
  //       authorName: "Dara Vaziri",
  //       authorJobPosition: "SENIOR ANALYST",
  //       text: "“We implemented Banner because we can <span>manage our entire portfolio</span> on it and have access to every stakeholder involved, along with live information. It provides a really meaningful <span>check and balance</span> in our CapEx projects.”",
  //       image: `${config.CLOUDFLARE_FULL_URL}/c53febdb-24d2-4904-abed-fde76d124500/public`,
  //     },
  //     results: {
  //       text: 'Less than a year after implementing the platform, FCP now has 965 projects on Banner. Given their unique holdings, digging into the nitty-gritty details of small capital item details is crucial to achieving a successful CapEx business plan. Banner provides a single source of truth across all of their projects, so every dollar spent can be analyzed to ensure they are adhering to their business plan while making well-informed decisions for the future.\nVaziri explains, "Banner helps us ensure properly allocated and attributed spend across our capital projects, so we have an even better view of what we are spending and where we are on our business plan."\n\nThe result is significant alignment and reliable data sets. Banner has provided FCP with unimpeded visibility to understand its actual capital expenditures, which provides greater clarity to its everyday decisions and future budgeting. Mr. Vaziri believes this transparency has been the single most significant value-add since Banner came aboard—affecting both the prioritization of projects, and consensus on what specific goals each project can expect to achieve.',
  //       image: `${config.CLOUDFLARE_FULL_URL}/f5334973-087e-439e-ba1f-c41096eac400/public`,
  //     },
  //   },
  // },
  {
    id: 3,
    slug: 'industrious',
    image: `${IND1}`,
    category: CaseStudyCategories.CO,
    title: 'Industrious (Office)',
    description: 'How Banner transformed business processes for a beloved coworking brand.',
    info: {
      text: 'Industrious Office is an independent, visionary-driven workspace solutions provider that specializes in creating, managing, and enhancing flexible office environments for businesses of all sizes, with 160+ locations across 65 cities.',
      logo: `${IndustriousWhite}`,
      challenge: {
        text: 'Industrious, a prominent provider of flexible workspaces, faced several challenges in managing their office build outs. With 160+ locations across the 65 cities in the United States, tracking costs, budgeting, and communication became increasingly cumbersome. \n\nThe inefficiencies of their former software providers led to fragmented processes, time-consuming manual data entry, and limited visibility into project milestones and reporting. Industrious required a more comprehensive and streamlined solution that could automate collaboration between teams, integrate with key systems, and provide customized reporting.',
        image: `${IND1}`,
      },
      solution: {
        text: "Banner's initial discussions with Industrious quickly identified the key value propositions that would address their pain points and optimize their project management processes. Banner offered to roll multiple systems into one, automate internal reporting, streamline project milestone tracking, and develop custom functionality for tracking tenant improvement (TI) reimbursement data and cash flow projections. \n\nThe first challenge Banner overcame was integrating with Salesforce, which the Real Estate team uses to track new opportunities. When a new site is being due diligenced, Banner now automatically creates a new projects and sets up tasks for the Pre-Construction team. \n\nIndustrious prides itself on its internal reporting, which allows it to deliver tens of projects on time and on budget, at the same time.  The only drawback to this was the time required to create and maintain the custom reports in Google Sheets.  With its custom tagging functionality and milestone features, Banner allows the Industrious team to create complex custom reports that meet their businesses needs. \n\nThe Finance team at Industrious was perhaps the biggest beneficiary of using Banner.  Banner allows the team to track complex logic about the funding structure of a particular project, accounting for who is paying for what between the company and the landlord.  Banner bakes all of this data into its cash flow projection feature, allowing Industrious to have automated, laser-precise cash flow projections.",
        image: `${IND2}`,
      },
      quote: {
        authorName: 'Jim Gentili',
        authorJobPosition: 'Senior Manager, Design & Construction, Industrious',
        text: "“Banner has revolutionized the way we manage our projects. The <span>automation, custom reporting, and seamless integration</span> with our existing systems have been a game-changer. We <span>couldn't be happier</span> with the results.”",
        image: `${IND3}`,
      },
      results: {
        text: "Since implementing Banner's solution, Industrious has experienced significant improvements in their project management and reporting processes. They have reduced the administrative work involved in their projects by over 80%, saving valuable time and resources. \n\nBy consolidating multiple systems into one, Industrious now benefits from a centralized platform that simplifies contract signing, invoice approvals, change order management, and project status communication. This has led to increased efficiency and collaboration across their teams.\n\nFurthermore, Banner's custom functionality for tracking TI reimbursement data has provided Industrious with better visibility into their financials, enabling them to make more informed decisions regarding their projects. \n\nOverall, the partnership with Banner has empowered Industrious to optimize their Design & Construction processes, allowing the team to focus on providing exceptional flexible workspaces for their clients.",
        image: `${IND4}`,
      },
    },
  },
];
export default caseStudies;
