/* eslint-disable func-names */

//
// @typedefs in this file are essentially exported types
//

/**
 * @template T
 * @typedef {Exclude<T, null>} NotNull
 */
/**
 * @template T
 * @typedef {Exclude<T, null | undefined>} NotNullish
 */
/**
 * @template T
 * @typedef {Exclude<T, undefined>} NotUndefined
 */

// export type PromiseResolve<T> = T extends Promise<infer U> ? U : never;
/**
 * @typedef {T extends Promise<infer U> ? U : never} PromiseResolve
 * @template T
 */

// export type PromiseReturn<T extends (...args: any[]) => Promise<any>> = PromiseResolve<ReturnType<T>>;
/**
 * @typedef {PromiseResolve<ReturnType<T>>} PromiseReturn
 * @template {(...args: any[]) => any} T
 */

/**
 * @typedef {T | AllAbsent<T>} MaybeEmptyObject
 * @template T
 */

/**
 * @typedef {{ [key in keyof O]?: undefined }} AllAbsent
 * @template O
 */

/**
 * @typedef {{ [K in keyof O]: O[K] extends V ? K : never }[keyof O]} KeysWithValOfType
 * @template V, O
 */
// export type KeysWithValOfType<V, O> = { [K in keyof O]: O[K] extends V ? K : never }[keyof O] // https://stackoverflow.com/q/54520676

/**
 * @typedef {T extends ReadonlyArray<infer ElementType> ? ElementType : never} Values
 * @template {ReadonlyArray<unknown>} T
 */

// export type ObjVals<O> = O[keyof O]
/**
 * @typedef {O[keyof O]} ObjVals
 * @template O
 */

/**
 * Same as TypeScripts built-in {@link Exclude} but in inline expression form for your convenience.
 * @template T
 * @template U
 * @returns {Exclude<T, U>}
 */
exports.tsExclude = function (/** @type {T} */ x, /** @type {U} */ exclude) {
  // eslint-disable-line no-unused-vars
  return x;
};

/**
 * @template T, Phantom
 * @typedef {T & {
 *   [key in Extract<keyof Phantom, `_ts_hint_${any}` | '_ts_hint'>]: Phantom[key];
 * }} AssignPhantom
 */

/**
 * @template {any[]} T
 * @typedef {T[number][]} ArrayWithoutPhantomProps
 * Easy way to reverse the effect of `tsAssignPhantomType`
 */

/**
 * Easy way to reverse the effect of `tsAssignPhantomType`
 * @template {readonly any[]} T
 * @param {T} array
 */
exports.arrayWithoutPhantomProps = function (array) {
  return /** @type {ArrayWithoutPhantomProps<T>} */ (array);
};

/**
 * @template T
 * @param {T} x
 * @returns {Exclude<T, undefined>}
 */
exports.tsAssumeNotUndefined = function (x) {
  return x;
};

/**
 * @template T
 * @param {T} x
 * @returns {Exclude<T, null>}
 */
exports.tsAssumeNotNull = function (x) {
  return x;
};

/**
 * Same as `!` non-null assertion operator in TypeScript.
 * However in js that syntax is not possible so you can use `tsAssumeNotNully` instead for same effect.
 * @template T
 * @param {T} x
 * @returns {Exclude<T, null | undefined>}
 */
exports.tsAssumeNotNully = function (x) {
  return x;
};

/**
 * _“Have your cake and eat it too.”_
 * Restrict type of {@link specific} and derive type of {@link specific} too. :)
 * @param {Extendable} extendable dummy throw-away value
 * @param {Specific} specific returned as-is
 * @template Extendable
 * @template {Extendable} Specific
 */
exports.typeMustExtend = function (extendable, specific) {
  return specific;
};

/**
 * @template {Record<keyof any, any>} O
 * @template {KeysWithValOfType<readonly any[], O>} K
 * @param {O} obj
 * @param {K} prop
 * @returns {O extends { [key in K]: readonly [] } ? null : O}
 */
exports.hasPropNonEmptyArray = function (obj, prop) {
  return obj[prop].length === 0 ? null : obj;
};
