import React from 'react';
import styled from 'styled-components';
import config from '../../../../../config';
import theme from '../../../../../theme/theme';

const StyledImagesDesktop = styled.div`
  margin-top: 99px;
  display: none;
  z-index: 8;

  img {
    filter: drop-shadow(0px 0px 20.0715px rgba(22, 31, 38, 0.05));
    object-fit: cover;
    object-position: center;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    display: flex;
    position: absolute;
    right: 0;
    height: 415px;
    justify-content: space-between;
    column-gap: 24.5px;
    margin-right: 40px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    margin-right: 72px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-right: 62px;
    height: 503px;
    column-gap: 30px;
  }
`;
const StyledImage = styled.div`
  display: flex;
  align-items: flex-end;

  img {
    width: 222px;
    height: 288px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    img {
      width: unset;
      height: unset;
    }
  }
`;
const StyledHeroImgContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 8.18px;

  .top-image {
    width: 269px;
    height: 119px;
  }

  .bottom-image {
    width: 156px;
    height: 39px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    .top-image,
    .bottom-image {
      width: unset;
      height: unset;
    }
  }
`;
const StyleImagesContent = styled(StyledHeroImgContent)`
  align-items: flex-end;
  position: relative;
  padding-right: 26.34px;

  .top-image {
    width: 247px;
    height: 84px;
  }

  .bottom-image {
    width: 223px;
    height: 84px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    .top-image,
    .bottom-image {
      width: unset;
      height: unset;
    }
  }
`;
const StyledHeroImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 90px;
`;
function ImagesDesktop() {
  return (
    <StyledImagesDesktop>
      <StyledImage>
        <img
          data-aos="fade-up"
          src={`${config.CLOUDFLARE_FULL_URL}/48291058-932a-4a0b-e900-db7e26b87e00/public`}
          width="270"
          height="349"
          alt=""
        />
      </StyledImage>

      <StyledHeroImg>
        <StyledHeroImgContent>
          <img
            className="top-image"
            data-aos="zoom-in"
            data-aos-delay="100"
            src={`${config.CLOUDFLARE_FULL_URL}/6c7ee126-25cb-479e-c382-0ae220532e00/public`}
            width="326"
            height="144"
            alt=""
          />
          <img
            className="bottom-image"
            data-aos="zoom-in"
            data-aos-delay="200"
            src={`${config.CLOUDFLARE_FULL_URL}/168ca164-da96-43b6-b9ec-c47163a61400/public`}
            width="189"
            height="47"
            alt=""
          />
        </StyledHeroImgContent>

        <StyleImagesContent>
          <img
            className="top-image"
            data-aos="fade-left"
            data-aos-delay="200"
            src={`${config.CLOUDFLARE_FULL_URL}/22e9437e-fb58-4eb6-1e4c-db0b58474000/public`}
            width="300"
            height="101"
            style={{ position: 'relative' }}
            alt=""
          />
          <img
            className="bottom-image"
            data-aos="fade-left"
            data-aos-delay="300"
            src={`${config.CLOUDFLARE_FULL_URL}/b0012f8a-e6e8-4528-c3b4-07b079325c00/public`}
            width="270"
            height="102"
            style={{
              position: 'absolute',
              top: 'calc(100% - 18px)',
              right: 0,
              zIndex: -1,
            }}
            alt=""
          />
        </StyleImagesContent>
      </StyledHeroImg>
    </StyledImagesDesktop>
  );
}
export default ImagesDesktop;
