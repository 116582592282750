import React from 'react';
import { forwardRef, memo } from 'react';
const PricingIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M29 56c14.36 0 26-11.64 26-26S43.36 4 29 4 3 15.64 3 30s11.64 26 26 26Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.918 23.227a6.351 6.351 0 0 0-2.045-3.744A5.853 5.853 0 0 0 29 18c-3.314 0-6 2.286-6 5.75 0 3.462 3 5.032 6 5.75 3 .717 6 2.285 6 5.75 0 3.464-2.686 5.75-6 5.75a5.87 5.87 0 0 1-4.243-1.837A6.419 6.419 0 0 1 23 34.728M29 17.775V14m0 32v-3.776"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M48 59c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13Z" fill="#F25E53" />
    <path
      d="m42.682 47.084 3.751 3.841 8.2-8.536"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(PricingIcon);
const Memo = memo(ForwardRef);
export default Memo;
