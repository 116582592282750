import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme/theme';

const StyledWrapper = styled.div`
  padding: 32px;
  border-radius: 8px;
  background-color: ${theme.colors['Anti Flash White']};
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  min-width: 168px;
  width: 168px;
  flex-grow: 1;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: 320px;
    flex-direction: row;
    justify-content: unset;
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    width: 355px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    width: 435px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    width: 260px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    width: 278px;
  }

  > svg {
    flex-shrink: 0;
  }
`;
const StyledTitle = styled.h3`
  font-size: 16px;
  line-height: 16px;
  text-align: center;

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    text-align: unset;
    font-size: 20px;
    line-height: 32px;
  }
`;
function FeatureCard({ icon, title, className, ...rest }) {
  return (
    <StyledWrapper {...rest} className={className}>
      {icon}
      <StyledTitle>{title}</StyledTitle>
    </StyledWrapper>
  );
}
export default FeatureCard;
