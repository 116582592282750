import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import BenefitSection from '../components/Sections/Home/BenefitSection';
import CaseStudySection from '../components/Sections/Home/CaseStudySection';
import CustomersSection from '../components/Sections/Home/CustomersSection';
import FeaturesSection from '../components/Sections/Home/FeaturesSection/FeaturesSection';
import Footer from '../components/Sections/common/Footer';
import HeroSection from '../components/Sections/Home/HeroSection';
import OurToolsSection from '../components/Sections/Home/OurToolsSection';
import TestimonialsSection from '../components/Sections/Home/TestimonialsSection';
import VideoSection from '../components/Sections/Home/VideoSection';
import theme from '../theme/theme';
import HomeHeader from '../components/Sections/Home/HomeHeader';
import OurToolsFooter from '../components/Sections/common/OurToolsFooter';

const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
`;
const StyledFooterWrapper = styled.div`
  position: relative;
`;
const StyledFooter = styled(Footer)`
  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 187px;
    padding-top: 193px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    margin-top: 204px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 250px;
  }
`;
function IndexPage() {
  return (
    <StyledLayout header={<HomeHeader />} footer={<OurToolsFooter />}>
      <HeroSection />
      <BenefitSection />
      <VideoSection />
      <CustomersSection />
      <FeaturesSection />
      <TestimonialsSection />
      <CaseStudySection />
    </StyledLayout>
  );
}
export default IndexPage;
