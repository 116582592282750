import axios from 'axios';
import type { ViaJson } from 'banner-typescript-rest-api/src/types/util/schema-types';
import { omit } from 'lodash';
import type { PromiseReturn } from '../../common/util/type-utils';
import type { StatusesWeUseInReturnValues } from '../../server/utility/reply';
import type { GetTokenSilently } from '../libraries/react-auth0-spa';
import { useMemoWithTypeCheck } from '../util/react-memo-util';
import type { AxiosRequestConfigEx } from '../util/throttleAdapterEnhancer';
import _apiFnsRead from './_apiFnsRead';
import _apiFnsWrite from './_apiFnsWrite';

/**
 * Shorthand way to easily get the return value of `useAppContext().api['…']`
 *
 * (See also: `ApiHookResults` for return value of `useApi['…']`)
 *
 * If using this causes you to get a circular type error. e.g.
 *   - "Type alias 'ApiResponseTypes' circularly references itself.", or
 *  - "'...' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer."
 *
 * then try using {@link SrcServerRes} or {@link ServerTsRes} directly
 * similar to how `_apiFnsRead.ts` and `_apiFnsWrite.ts` are doing it.
 * (although, in those files it is aliased to shorthand `JsRes` and `TsRes`)
 */
export type ApiResponseTypes = {
  [key in keyof Omit<ApiContext, '_ts_hint_memoized'>]: PromiseReturn<ApiContext[key]>;
};

/**
 * HTTP status codes that result in Axios HTTP throwing into a `catch` instead of just simply returning a value
 */
export type AxiosWillThrow = Exclude<StatusesWeUseInReturnValues, 200 | 204>;

/**
 * API response for use in client.
 * Specifically for use with `server-ts` endpoints. Not suitable for `src/server` endpoints
 * @see {@link SrcServerRes}
 */
export type ServerTsRes<Fn extends (...args: any[]) => Promise<any>> = ViaJson<PromiseReturn<Fn>>;

/**
 * API response for use in client.
 * Specifically for use with `src/server` endpoints. Not suitable for `server-ts` endpoints.
 * @see {@link SrcServerResJson}
 * @see {@link ServerTsRes}
 */
export type SrcServerRes<Fn extends (...args: any[]) => Promise<{ status: number; response: any }>> =
  SrcServerResJson<LegacyControllerReturn<Fn>>;

/**
 * For use in backend. Does not expect `ObjectId` and `Date`s to be converted to `string`s like {@link SrcServerRes} would
 * because this is skipping the {@link SrcServerResJson} / {@link ViaJson} layer.
 */
export type LegacyControllerReturn<
  Fn extends (...args: any[]) => Promise<{ status: number; response: any }>,
> = Exclude<PromiseReturn<Fn>, { status: AxiosWillThrow }>['response'];

/**
 * I have noticed that if a controller in `src/server` returns `{ status: ..., response: null | undefined}`
 * then the HTTP response will be `Content-Length: 0` with no `Content-Type`
 * and `axios.get` will render this as empty string `""` response data.
 *
 * So this type handles that characteristic before passing to {@link ViaJson}
 *
 * Not suitable for `server-ts` responses.
 */
export type SrcServerResJson<Response> = Response extends null | undefined ? '' : ViaJson<Response>;

/**
 * Similar to `MaybeViaJson` but anticipates the additional quirk that `SrcServerResJson` is handling.
 */
export type LegacyControllerResCommon<
  T extends (...args: any[]) => Promise<{ status: number; response: any }>,
> = LegacyControllerReturn<T> | SrcServerRes<T>;

/**
 * The `src/server` (non-`server-ts`) api / axios is rendering `null` results as empty string `''` which can cause a lot of type errors. However, in most cases `''.missingProperty` behaves the same as `{}.missingProperty`. So, you can use this wrapper for convenience.
 */
export type EmptyStringAsObject<T> = T extends '' ? {} : T;

export type NotEmptyString<T> = T extends '' ? never : T;

export type ApiContext = ReturnType<typeof _useMakeApiContext>;
export function _useMakeApiContext(getTokenSilently: GetTokenSilently) {
  return useMemoWithTypeCheck(makeApiContext, [getTokenSilently]);
}

export type HttpResponse<D> = {
  data: D;
};

function makeApiContext(getTokenSilently: GetTokenSilently) {
  const makeApiFns = makeMakeApiFns(getTokenSilently);

  return {
    ..._apiFnsRead(makeApiFns),
    ..._apiFnsWrite(omit(makeApiFns, 'get')),
  };
}

export type _MakeApiFns = ReturnType<typeof makeMakeApiFns>;
export function makeMakeApiFns(getTokenSilently: GetTokenSilently) {
  return {
    get: makeApiFnConstructor_get,
    post: makeApiFnConstructor_post,
    extend,
  };

  function extend<
    OrigFnArgs extends any[],
    OrigData,
    TransformedResponse,
    PossiblyTweakedArgList extends any[] = OrigFnArgs,
  >(
    originalApiFn: { vanilla: (...args: OrigFnArgs) => Promise<HttpResponse<OrigData>> },
    customTransformFn: (
      orig: (...args: OrigFnArgs) => Promise<OrigData>,
      ...args: PossiblyTweakedArgList
    ) => TransformedResponse,
  ) {
    const vanilla = async (...args: PossiblyTweakedArgList) => {
      let response: HttpResponse<OrigData> | undefined;
      const originalStoreVanilla = async (...args: OrigFnArgs) => {
        response = await originalApiFn.vanilla(...args);
        return response.data;
      };
      const transformedData = await customTransformFn(originalStoreVanilla, ...args);
      if (!response) {
        throw new Error('the custom function did not call the orig function so no api call was triggered.'); // protection can be commented out if it gets in the way of a legitimate use case
      }
      return {
        // __dataBeforeTransformation: response.__dataBeforeTransformation ?? response.data, // could be useful in future
        ...response,
        data: transformedData,
      };
    };
    return unwrapDataKeepVanilla(vanilla);
  }

  function makeApiFnConstructor_get(
    endpoint: `/api/${string}`,
    // defaultConfig?: AxiosRequestConfigEx, // we could add this but haven't needed it yet
  ) {
    /**
     *
     * Without `thinWrapper`, `_apiFnsRead.ts` and `_apiFnsWrite.ts` syntax is:
     * ```
     *     getTeam: get<JsRes<TeamController['getTeam']>>('/api/plus/managers/getTeam')(),
     * ```
     * with `thinWrapper`, `_apiFnsRead.ts` and `_apiFnsWrite.ts` syntax is:
     * ```
     *     getTeam: get('/api/plus/managers/getTeam')<JsRes<TeamController['getTeam']>>(),
     * ```
     *
     * So we added `thinWrapper` to make `_apiFnsRead.ts` and `_apiFnsWrite.ts` have
     * slightly more convenient syntax - trying to keep all the runtime stuff on the beginning of line
     * and type params at end of line especially because type params might get long in the future when
     * if we add `QueryParams` and `PostdData`. We want the `/api/...` {@link endpoint} string to be
     * closer to the beginning of the line since that is more important than the type param.
     */
    return function thinWrapper<Response = any, QueryParams = Record<string, any>>() {
      const vanilla = async (params?: QueryParams, config?: AxiosRequestConfigEx) => {
        // config = mergeI(defaultConfig, config);

        const token = await getTokenSilently();
        const headers = { Authorization: `Bearer ${token}`, ...config?.headers };

        return axios.get<Response>(endpoint, {
          params,
          ...config,
          headers,
        });
      };
      return unwrapDataKeepVanilla(vanilla);
    };
  }

  function makeApiFnConstructor_post(
    endpoint: `/api/${string}`,
    // defaultConfig?: AxiosRequestConfigEx, // we could add this but haven't needed it yet
  ) {
    return function thinWrapper<Response = any, PostData = Record<string, any>>() {
      const vanilla = async (postdata: PostData, config?: AxiosRequestConfigEx) => {
        // config = mergeI(defaultConfig, config);

        const token = await getTokenSilently();
        const headers = { Authorization: `Bearer ${token}`, ...config?.headers };

        return axios.post<Response>(endpoint, postdata, {
          ...config,
          headers,
        });
      };
      return unwrapDataKeepVanilla(vanilla);
    };
  }
}

function unwrapDataKeepVanilla<VanillaFnArgs extends any[], D>(
  vanilla: (...args: VanillaFnArgs) => Promise<HttpResponse<D>>,
) {
  return Object.assign(unwrapData(vanilla), { vanilla });
}

function unwrapData<VanillaFnArgs extends any[], D>(
  vanilla: (...args: VanillaFnArgs) => Promise<HttpResponse<D>>,
) {
  return async (...args: VanillaFnArgs) => {
    const { data } = await vanilla(...args);
    return data;
  };
}

export function unwrap<VanillaFnArgs extends any[], D, MD>(
  fn: (...args: VanillaFnArgs) => Promise<HttpResponse<D>>,
  mapResponse: (res: HttpResponse<D>) => MD,
) {
  return async (...args: VanillaFnArgs) => {
    const res = await fn(...args);
    return mapResponse(res);
  };
}
