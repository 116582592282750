import React from 'react';
import { forwardRef, memo } from 'react';
const DataUpdateIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M52.464 60.526H10.105A2.105 2.105 0 0 1 8 58.421V12.105A2.105 2.105 0 0 1 10.105 10h50.527a2.105 2.105 0 0 1 2.105 2.105v31.11"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.286 52.857H16.57m10.715-34.286H16.57m10.715 16.327H16.57"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.737 26.842H8m30 16.842H8"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={58.357} cy={55.929} r={13.929} fill="#F25E53" />
    <path
      d="m62.485 56.38 2.477 3.735 2.83-3.383m-13.209-.352-2.83-3.454-2.831 3.454"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.58 62.845a6.396 6.396 0 0 1-4.195.788 6.574 6.574 0 0 1-3.794-2.04 7.339 7.339 0 0 1-1.807-3.49 7.585 7.585 0 0 1 .22-3.969m3.174-3.938a6.397 6.397 0 0 1 4.194-.787 6.574 6.574 0 0 1 3.794 2.04 7.347 7.347 0 0 1 1.777 3.499 7.584 7.584 0 0 1-.247 3.96"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(DataUpdateIcon);
const Memo = memo(ForwardRef);
export default Memo;
