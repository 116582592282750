import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  FastForwardIcon,
  MaximizeIcon,
  MinimizeIcon,
  MutedIcon,
  PauseIcon,
  PlayIcon,
  RewindIcon,
  VolumeIcon,
} from '../../Icons';
import { ResetButton } from '../../../theme';
import theme from '../../../theme/theme';
import { getHourFormat } from '../../../utils/timeFormat';
import ProgressBar from '../ProgressBar';

const StyledControlsWrapper = styled.div`
  display: grid;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: ${theme.colors.Jet};
  transition: opacity 0.3s;
  border-radius: 10px;
  backdrop-filter: blur(3.46px);
  padding: 10px;
  justify-content: space-between;
  column-gap: 10px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: minmax(auto, 115px) auto minmax(auto, 115px);

  @media screen and (min-width: ${theme.breakpoints['1440']}) {
    left: 22px;
    right: 22px;
    bottom: 22px;
    border-radius: 22.15px;
    padding: 22px 39px;
    column-gap: 50px;
    transition-delay: 2s;
    grid-template-rows: 1fr;
    grid-template-columns: 115px 142px 24px 1fr;

    ${({ isPlaying }) =>
      isPlaying &&
      css`
        opacity: 0;
      `}
  }
`;
const StyledSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-row: 2;

  > svg {
    flex-shrink: 0;
  }
`;
const StyledVolumeWrapper = styled(StyledSectionWrapper)`
  column-gap: 5.54px;
  flex-grow: 1;
  max-width: 115px;
  display: flex;

  svg {
    width: 22px;
    height: 22px;
  }

  @media screen and (min-width: ${theme.breakpoints['1440']}) {
    svg {
      width: unset;
      height: unset;
    }
  }
`;
const StyledPlayWrapper = styled(StyledSectionWrapper)`
  column-gap: 7px;

  svg {
    width: 24px;
    height: 24px;
  }

  > .play-button {
    width: 32px;
    height: 32px;
  }

  @media screen and (min-width: ${theme.breakpoints['1440']}) {
    column-gap: 22px;

    svg {
      width: unset;
      height: unset;
    }
  }
`;
const StyledSettingsWrapper = styled(StyledSectionWrapper)`
  column-gap: 16.6px;
  justify-content: end;
  display: flex;

  > .fullscreen-button svg {
    width: 17px;
    height: 17px;
  }

  @media screen and (min-width: ${theme.breakpoints['1440']}) {
    > .fullscreen-button svg {
      width: unset;
      height: unset;
    }
  }
`;
const StyledProgressWrapper = styled(StyledSectionWrapper)`
  column-gap: 12px;
  flex-grow: 1;
  grid-row: 1;
  grid-column: 1/-1;

  @media screen and (min-width: ${theme.breakpoints['1440']}) {
    grid-row: 2;
    grid-column: unset;
  }
`;
const StyledButton = styled.button`
  ${ResetButton};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledText = styled.span`
  color: ${theme.colors.White};
  font-size: 8.86px;
  line-height: 11px;

  @media screen and (min-width: ${theme.breakpoints['1440']}) {
    font-size: 9.8px;
    line-height: 12px;
  }
`;
const StyledProgressBar = styled(ProgressBar)`
  @media screen and (min-width: ${theme.breakpoints['1440']}) {
    .rc-slider {
      padding: 3px 0;
    }

    .rc-slider-handle {
      margin-top: -4.5px;
    }

    .rc-slider-rail,
    .rc-slider-track {
      height: 7px;
    }
  }
`;
function Controls(
  {
    className,
    playing,
    muted,
    volume,
    fullScreen,
    progress,
    currentTime,
    totalDuration,
    handleMuteButton,
    handleOnChangeVolume,
    handleSeekSeconds,
    handlePlayPause,
    handleFullscreen,
    handleOnChangePlayback,
  },
  ref,
) {
  return (
    <StyledControlsWrapper ref={ref} className={`${className} controls-wrapper`} isPlaying={playing}>
      <StyledVolumeWrapper>
        <StyledButton className="volume-button" onClick={handleMuteButton}>
          {!muted ? <VolumeIcon /> : <MutedIcon />}
        </StyledButton>
        <ProgressBar progress={muted ? 0 : volume} onChange={handleOnChangeVolume} />
      </StyledVolumeWrapper>
      <StyledPlayWrapper>
        <StyledButton className="forward-button" onClick={handleSeekSeconds(-5)}>
          <RewindIcon />
        </StyledButton>
        <StyledButton className="play-button" onClick={handlePlayPause}>
          {!playing ? <PlayIcon /> : <PauseIcon />}
        </StyledButton>
        <StyledButton className="forward-button" onClick={handleSeekSeconds(5)}>
          <FastForwardIcon />
        </StyledButton>
      </StyledPlayWrapper>
      <StyledSettingsWrapper>
        <StyledButton className="fullscreen-button" onClick={handleFullscreen}>
          {!fullScreen ? <MaximizeIcon /> : <MinimizeIcon />}
        </StyledButton>
      </StyledSettingsWrapper>
      <StyledProgressWrapper>
        <StyledText>{getHourFormat(currentTime)}</StyledText>
        <StyledProgressBar progress={progress} onChange={handleOnChangePlayback} withScrubber />
        <StyledText>{getHourFormat(totalDuration)}</StyledText>
      </StyledProgressWrapper>
    </StyledControlsWrapper>
  );
}
export default forwardRef(Controls);
