import React from 'react';
import { forwardRef, memo } from 'react';
const PinIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M63.9 36.3c.1-.8.1-1.5.1-2.3a24 24 0 0 0-48 0c0 .8 0 1.6.1 2.3v.3C18.1 55.6 40 69 40 69s21.9-13.6 23.8-32.3l.1-.4Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.579 39.73a1 1 0 0 0-1.158 0l-5.165 3.666c-.793.563-1.842-.223-1.524-1.142l1.974-5.713a1 1 0 0 0-.373-1.146l-5.062-3.537c-.802-.56-.405-1.82.573-1.82h6.294a1 1 0 0 0 .948-.681l1.966-5.863c.305-.91 1.591-.91 1.896 0l1.966 5.863a1 1 0 0 0 .948.682h6.294c.978 0 1.374 1.26.572 1.82l-5.062 3.536a1 1 0 0 0-.372 1.146l1.974 5.713c.317.919-.731 1.705-1.524 1.142l-5.165-3.666Z"
      fill="#F25E53"
    />
  </svg>
);
const ForwardRef = forwardRef(PinIcon);
const Memo = memo(ForwardRef);
export default Memo;
