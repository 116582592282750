import { useCallback, useMemo } from 'react';
import { _partialLoadIndRef, useAppContext } from '../components/app-layout/AppContext';

export type PartialLoadHandler = ReturnType<typeof usePartialLoad>;

const MAX_COUNT = 9999;
const VERBOSE = false;

const GLOBAL = {
  count: 0,
};

const setCount = (newCount: number) => {
  GLOBAL.count = newCount;
};

export const usePartialLoad = () => {
  const { [_partialLoadIndRef]: partialLoadIndRef } = useAppContext();

  const reset = useCallback(() => {
    if (VERBOSE) {
      console.debug('partialLoad :: reset');
    }

    const isLoadingBefore = isLoading();
    setCount(0);
    _rerenderIndicatorOnlyIfNeed(isLoadingBefore);
  }, []);

  const start = useCallback((verboseLoggingId?: string) => {
    if (VERBOSE) {
      console.debug('usePartialLoad :: start ::', verboseLoggingId);
    }

    const isLoadingBefore = isLoading();
    setCount(GLOBAL.count === MAX_COUNT ? 1 : GLOBAL.count + 1);
    _rerenderIndicatorOnlyIfNeed(isLoadingBefore);
  }, []);

  const end = useCallback((verboseLoggingId?: string) => {
    if (VERBOSE) {
      console.debug('usePartialLoad :: end ::', verboseLoggingId);
    }

    const isLoadingBefore = isLoading();
    setCount(GLOBAL.count > 1 ? GLOBAL.count - 1 : 0);
    _rerenderIndicatorOnlyIfNeed(isLoadingBefore);
  }, []);

  const isLoading = () => GLOBAL.count >= 1;

  return useMemo(() => ({ isLoading, end, reset, start }), [isLoading, end, reset, start]);

  function _rerenderIndicatorOnlyIfNeed(isLoadingBefore: boolean) {
    const isLoadingAfter = isLoading();
    if (isLoadingBefore !== isLoadingAfter) {
      partialLoadIndRef.current?.triggerTinyRerender({});
    }
  }
};
