const OK = 'ok';
const ERR = 'error';

class Result {
  constructor(value) {
    if (value instanceof Result) {
      this.value = value.value;
    } else if (Array.isArray(value)) {
      this.value = value;
    } else {
      this.value = [OK, value];
    }
  }

  andThen(fn) {
    return this.isErr() ? this : fn(this.value[1]);
  }

  out(okFn, errFn) {
    return this.isErr() ? errFn(this.value[1]) : okFn(this.value[1]);
  }

  isErr() {
    return this.value[0] === ERR;
  }

  isOk() {
    return this.value[0] === OK;
  }

  map(fn) {
    return this.isErr() ? this : Result.ok(fn(this.value[1]));
  }

  unwrap() {
    if (this.isErr()) {
      throw new Error(this.value[1]);
    }

    return this.value[1];
  }

  unwrapErr() {
    if (this.isErr()) {
      return this.value[1];
    }

    throw new Error('Attempted to unwrap an OK value as an error');
  }

  unwrapOr(value) {
    if (this.isErr()) {
      return value;
    }

    return this.value[1];
  }
}

Result.err = function err(message) {
  return new Result([ERR, message]);
};

Result.ok = function ok(value) {
  return new Result([OK, value]);
};

module.exports = {
  Result,
};
