import React from 'react';
import Tagline from '../../../../components/common/Tagline';
import { LayoutMixin } from '../../../../theme';
import theme, { BREAKPOINTS } from '../../../../theme/theme';
import { Children, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Navigation } from 'swiper';
import { Swiper as ReactSwiper } from 'swiper/react';
import useGetWindowWidth from '../../../../hooks/useGetWindowWidth';
import CustomNavigation from './CustomNavigation';
const StyledWrapper = styled.div`
  overflow: hidden;
`;
const StyledInnerWrapper = styled.div`
  ${LayoutMixin};
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  position: relative;

  .swiper {
    max-width: 100%;
    width: 100%;
    overflow: visible;

    .swiper-slide {
      height: auto;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    flex-direction: row;
    justify-content: space-between;

    .swiper {
      width: 0px;
      flex-grow: 1;
      clip-path: inset(0 -100% 0 0);
      position: unset;
      margin: 0;
    }
  }
`;
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    box-sizing: content-box;
    max-width: 273px;
    padding-right: 23px;
    flex-shrink: 0;
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    padding-right: 56px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    padding-right: 87px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    padding-right: 151px;
  }
`;
const StyledTagline = styled(Tagline)`
  padding-left: 2px;
`;
const StyledTitle = styled.h2`
  color: ${theme.colors['Moonlit Ocean']};
`;
const StyledNavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-left: ${theme.paddings.container.sm};
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: left;
    box-sizing: content-box;
    max-width: 273px;
    padding-right: 23px;
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    padding-right: 56px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    padding-right: 151px;
    margin-left: ${theme.paddings.container.md};
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    padding-right: unset;
    max-width: unset;
    margin-left: ${theme.paddings.container.xl};
  }
`;
const StyledCustomNavigation = styled(CustomNavigation)`
  flex-grow: 1;
`;
const SwiperConfig = {
  modules: [Navigation],
  grabCursor: true,
  spaceBetween: 16,
  breakpoints: {
    [BREAKPOINTS.xl]: { spaceBetween: 24 },
  },
};
const SwiperSection = ({ tagline, title, children, className, customSwiperConfig }) => {
  useGetWindowWidth();
  const [swiperRef, setSwiperRef] = useState();
  const [slide, setSlide] = useState(1);
  const [slideCount, setSlideCount] = useState(Children.count(children));
  const handleSlideChange = (swiper) => setSlide(swiper.realIndex + 1);
  const handleSlideCountChange = useCallback(
    (swiper) => setSlideCount((swiper.slides?.length || 1) - (swiper.loopedSlides || 0) * 2),
    [],
  );
  return (
    <StyledWrapper className={className}>
      <StyledInnerWrapper className="inner-wrapper">
        {(tagline || title) && (
          <StyledTextWrapper className="text-wrapper">
            {tagline && <StyledTagline data-aos="fade-up" data-aos-delay="100" text={tagline} />}
            {title && <StyledTitle data-aos="fade-up">{title}</StyledTitle>}
          </StyledTextWrapper>
        )}
        <ReactSwiper
          {...SwiperConfig}
          {...customSwiperConfig?.(SwiperConfig, swiperRef)}
          onSlideChange={handleSlideChange}
          onSlidesLengthChange={handleSlideCountChange}
          onInit={(s) => {
            setSwiperRef(s);
            handleSlideChange(s);
          }}
        >
          {children}
        </ReactSwiper>
        <StyledNavigationWrapper className="navigation-wrapper">
          <StyledCustomNavigation swiper={swiperRef} currentSlide={slide} slideCount={slideCount} />
        </StyledNavigationWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};
export default SwiperSection;
