.bodyModal {
  margin-top: 30px;
  width: 100%;
}

.GenericModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 36px;
  width: 100%;
  text-align: left;
}

.GenericModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.GenericModalTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
}

.GenericModalSubtitle {
  width: 100%;
  font-size: 14px;
  color: #7f7f7f;
  text-align: left;
  margin-top: 6px;
}

.GenericModalHeaderCloseIcon {
  height: 12px;
  width: 12px;
}

.GenericModalHeaderCloseIcon img {
  height: 12px;
  width: 12px;
}

.GenericModalBody {
}

.GenericModalFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
