import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

type ConditionalTooltipProps = TooltipProps & {
  show: boolean;
};

// eslint-disable-next-line react/function-component-definition
export const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({ show, ...other }) => {
  if (show) {
    return <Tooltip arrow placement="top" {...other} />;
  } else {
    return other.children;
  }
};
