const isDevMode = process.env.NODE_ENV === 'development';
const isAva = Boolean(process.env.AVA_TESTING);
const isCypress = typeof window !== 'undefined' && Boolean(window.Cypress);

exports.STAGE_DATA = {
  isAva,
  isCypress,
  isDevMode,
  isTesting: isAva || isCypress || isDevMode,
};
