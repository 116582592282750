/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

const useGetElementSize = (ref) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    const handleResize = () => {
      const computedStyle = getComputedStyle(el);
      let height = el.clientHeight;
      let width = el.clientWidth;
      height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
      width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      setSize({ width, height });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);
  return size;
};
export default useGetElementSize;
