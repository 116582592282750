import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: '9000',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    flexDirection: 'column',
  },
  message: {
    marginTop: '20px',
    color: 'white',
    fontWeight: '700',
    fontSize: '14px',
  },
}));

const useStylesFacebook = makeStyles({
  root: {
    position: 'relative',
    height: 'auto',
    zIndex: '9000',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: '#2a7de1',
    animationDuration: '2000ms',
    width: '60px!important',
    height: '60px!important',
  },
});
function FacebookProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress classes={{ root: classes.bottom }} />
    </div>
  );
}

function CircularProgressWithLabel(props) {
  const classes = useStylesFacebook();

  return (
    <Box className={classes.root}>
      <CircularProgress variant={'determinate'} classes={{ root: classes.bottom }} value={props.progress} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
      >
        {`${Math.round(props.progress)}%`}
      </Box>
    </Box>
  );
}

/**
 * @see `src\client\components\util\LoadingScopedOverlay.js` if full-page overlay would be overkill
 */
export default function Loading(props) {
  const classes = useStyles();

  if (props.open) {
    return (
      <div data-cy="loading-component">
        <Backdrop open={props.open} classes={{ root: classes.root }}>
          {/* <img src={BannerLoading} style={{ width: "200px" }} /> */}
          {props.progress && <CircularProgressWithLabel progress={props.progress} />}
          {!props.progress && <FacebookProgress />}
          <div className={classes.message}>{props.message}</div>
        </Backdrop>
      </div>
    );
  }
  return null;
}
