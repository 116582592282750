import React, { useState } from 'react';
import styled from 'styled-components';
import { CharWrapper, Linebreaker, Pace, WindupChildren } from 'windups';
import theme from '../../../../theme/theme';
import useGetElementSize from '../../../../hooks/useGetElementSize';

const StyledH1 = styled.h1`
  min-height: 1.25em;
  color: ${theme.colors['Deep Sea Coral']};
`;
export const StyledChar = styled.span`
  font-family: ${theme.fontFamily.Lexend}, sans-serif;

  @keyframes fadeIn {
    from {
      transform: translateX(30px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  display: inline-block;
`;
const TEXTS = ['Capital Spend', 'TI projects', 'Unit Renovations'];
function AnimatedSubtext({ containerRef }) {
  const [index, setIndex] = useState(0);
  const { width } = useGetElementSize(containerRef);
  return (
    <StyledH1>
      <Linebreaker fontStyle="49.5px Lexend" width={width}>
        <WindupChildren
          onFinished={() => {
            const newIndex = index + 1;
            setTimeout(() => setIndex(newIndex > TEXTS.length - 1 ? 0 : newIndex), 2000);
          }}
        >
          <Pace getPace={(c) => (c === ' ' ? 0 : 80)}>
            <CharWrapper element={StyledChar}>{TEXTS[index]}</CharWrapper>
          </Pace>
        </WindupChildren>
      </Linebreaker>
    </StyledH1>
  );
}
export default AnimatedSubtext;
