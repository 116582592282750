//
// These functions have no effect on the runtime value.
// They are purely for hints to TypeScript.
// Remember that to *assign* something is generally good, but to *assume* something might be risky.
//

/* eslint-disable func-names */
/* eslint-disable no-unused-vars */

/**
 * @typedef {T extends string | number | boolean | null | undefined
 *   ? T
 *   : T & { _ts_hint_memoized: true }} Memoized
 * @template T
 */

/**
 * Basic case, assign a phantom type. Use whenever you desire.
 *
 * @template Input
 * @template {Input extends { _ts_hint: any } ? Input['_ts_hint'] : string} Tag
 */
exports.tsAssignPhantomType = function (/** @type {Input} */ x, /** @type {Tag} */ tag) {
  return /** @type {typeof x & { _ts_hint: Tag }} */ (x);
};

/**
 * Only use this if you know it is safe to do so.
 * This could bypass some protections that prevent 'always reinstantiated values' from becoming memo deps.
 *
 * @template T
 */
exports.tsAssumeMemoized = function (/** @type {T} */ x) {
  return /** @type {Memoized<T>} */ (x);
};
