import axios from 'axios';
import LRUCache from 'lru-cache';
import throttleAdapterEnhancer from './throttleAdapterEnhancer';

// This uses the code specified in an Axios issue regarding the setting
// of global interceptors.
// https://github.com/axios/axios/issues/510#issuecomment-515939606

const _createAxios = axios.create;

axios.create = function createPatchedAxios(conf) {
  const instance = _createAxios(conf);
  const defaultIcs = axios.defaults.interceptors;

  const resInterceptor = defaultIcs && defaultIcs.response ? defaultIcs.response : false;
  const reqInterceptor = defaultIcs && defaultIcs.request ? defaultIcs.request : false;

  if (reqInterceptor) instance.interceptors.request.use(...reqInterceptor);
  if (resInterceptor) instance.interceptors.response.use(...resInterceptor);
  return instance;
};

const requestInterceptor = [
  function InjectCurrentTeamID(config) {
    const currentTeamID = sessionStorage.getItem('banner.currentTeamID');
    const bypass = sessionStorage.getItem('banner.teamCheckBypass');

    if (currentTeamID) {
      config.headers['X-BANNER-CURRENT-TEAM'] = currentTeamID;
    }

    if (bypass) {
      config.headers['X-BANNER-BYPASS-TEAM-CHECK'] = bypass;
    }

    return config;
  },

  function InjectCurrentTeamIDErrorHandler(error) {
    return Promise.reject(error);
  },
];

const responseInterceptor = [
  (response) => {
    return response;
  },
  (error) => {
    const errorMessage = error?.response?.data?.message;
    if (
      errorMessage === 'Forbidden: Selected Team is not valid' &&
      window.location.href.indexOf('/plus/switchError') === -1
    ) {
      window.location.href = '/plus/switchError';
    }
    return Promise.reject(error);
  },
];

axios.defaults.interceptors = {
  request: requestInterceptor,
  response: responseInterceptor,
};
axios.interceptors.request.use(...requestInterceptor);
axios.interceptors.response.use(...responseInterceptor);

const canUseLocalStorage = (() => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
})();

// Disable throttling for Cypress not to mess with intercepts
if (canUseLocalStorage && !window?.Cypress) {
  axios.defaults.adapter = createThrottleAdapter(axios.defaults.adapter);
}
function createThrottleAdapter(adapter) {
  // At most one GET request to the same URL in 2.5 seconds
  const throttleAdapter = throttleAdapterEnhancer(adapter, {
    threshold: 2500, // At most one GET request to the same URL in 2.5 seconds
    thresholdMutating: 1000, // At most one mutating request with the same data/URL in 1 second
    cache: new LRUCache({ max: 30, ttl: 5 * 60 * 1000 }), // max 30 requests with 5min ttl
  });
  return (reqConfig) => {
    const noThrottle = reqConfig.noThrottle || localStorage.getItem('noAxiosThrottle') === 'true';
    return noThrottle ? adapter(reqConfig) : throttleAdapter(reqConfig);
  };
}
