/* eslint-disable consistent-return */
import deepmerge from 'deepmerge';
import { clamp, isArray } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import screenfull from 'screenfull';

const DEFAULT_CONTROLS = {
  playing: false,
  muted: false,
  fullScreen: false,
  volume: 1,
  progress: 0,
};
const useVideoControls = ({ onClickPlay } = {}) => {
  const [controls, setControls] = useState(DEFAULT_CONTROLS);
  const containerRef = useRef(null);
  const controlsRef = useRef(null);
  const playerRef = useRef(null);
  const closeButtonRef = useRef(null);
  const handleMuteButton = () => setControls(deepmerge(controls, { muted: !controls.muted }));
  const handleOnChangeVolume = (value) => {
    if (isArray(value)) return;
    const volume = value / 100;
    setControls(deepmerge(controls, { volume, muted: !volume }));
  };
  const handleFullscreen = () => {
    if (!containerRef.current) return;
    screenfull.toggle(containerRef.current);
  };
  const handlePlayPause = () => setControls(deepmerge(controls, { playing: !controls.playing }));
  const handleOnProgress = (progress) => {
    const newProgress = clamp(progress, 0, 1);
    setControls(
      deepmerge(controls, {
        progress: newProgress,
        playing: newProgress === 1 ? false : controls.playing,
      }),
    );
  };
  const handleSeekSeconds = (seconds) => () => {
    const player = playerRef.current;
    if (!player) return;
    const newSeconds = clamp(player.getCurrentTime() + seconds, 0, player.getDuration());
    const newProgress = newSeconds / player.getDuration();
    player.seekTo(newSeconds, 'seconds');
    handleOnProgress(newProgress);
  };
  const handleOnChangePlayback = (value) => {
    const player = playerRef.current;
    if (!player || isArray(value)) return;
    const parsedValue = value / 100;
    player.seekTo(parsedValue, 'fraction');
    handleOnProgress(parsedValue);
  };
  const returnProps = {
    controls,
    setControls,
    handleMuteButton,
    handleOnChangeVolume,
    handleFullscreen,
    handlePlayPause,
    handleSeekSeconds,
    handleOnChangePlayback,
  };
  const handleContainerClick = (e) => {
    if ([controlsRef.current, closeButtonRef.current].some((el) => el?.contains(e.target))) return;
    if (!onClickPlay) return handlePlayPause();
    return onClickPlay(returnProps);
  };
  useEffect(() => {
    const toggle = () => setControls((prev) => deepmerge(prev, { fullScreen: screenfull.isFullscreen }));
    document.addEventListener('fullscreenchange', toggle);
    return () => document.removeEventListener('fullscreenchange', toggle);
  }, []);
  return {
    ...returnProps,
    containerRef,
    controlsRef,
    playerRef,
    closeButtonRef,
    handleOnProgress,
    handleContainerClick,
  };
};
export default useVideoControls;
