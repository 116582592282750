import React from 'react';

const BannerIcon = ({ className }) => (
  <svg
    width="150"
    height="33"
    viewBox="0 0 150 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 0.5V15.6379L2.94542 15.6444L3.98928 15.6562C7.29164 15.711 8.8799 15.9248 9.92377 16.4074L10.083 16.4857L10.0419 16.5078C8.85551 17.1052 6.9822 17.3269 2.56408 17.3386H0V32.5L2.64497 32.4778C9.66055 32.3722 14.971 30.1107 17.4529 26.3363L17.5916 26.1198C19.4752 23.0771 19.2184 19.5727 17.0921 16.7543L17.0267 16.6709L17.2539 16.4896L17.0215 16.3044L17.0832 16.2274C19.325 13.2512 19.4867 9.53554 17.3091 6.41197C14.7053 2.67802 9.29847 0.5 2.2097 0.5H0ZM13.5959 19.4932C16.1394 22.8606 12.9141 27.0862 4.85467 27.8649L4.42069 27.9027V21.8055L5.21803 21.7807C9.59635 21.6203 11.6507 21.0282 13.4213 19.634L13.5972 19.4918L13.5959 19.4932ZM4.42069 11.1762V5.07255L4.45793 5.07516C12.7125 5.72465 16.1227 9.94506 13.6729 13.3751L13.5946 13.4808L13.5677 13.4586C11.7675 11.9809 9.73502 11.3679 5.25142 11.2023L4.41941 11.1762H4.42069Z"
      fill="#F25E53"
    />
    <path
      d="M30.8589 29.4473V3.17932H41.8689C43.5689 3.17932 45.0031 3.42973 46.1728 3.92924C47.3425 4.43006 48.2284 5.17345 48.8331 6.16204C49.4366 7.15063 49.7383 8.34528 49.7383 9.746C49.7383 11.1467 49.3557 12.4235 48.593 13.4239C47.8291 14.4242 46.7583 15.1128 45.3793 15.4871L45.342 14.7372C46.4989 14.9876 47.5094 15.431 48.3709 16.0701C49.2325 16.7079 49.904 17.4956 50.3842 18.4346C50.8644 19.3736 51.1045 20.4431 51.1045 21.643C51.1045 22.9185 50.9016 24.0453 50.4946 25.0209C50.0876 25.9964 49.4905 26.8102 48.7022 27.4597C47.9138 28.1105 46.9778 28.6048 45.8941 28.9426C44.8105 29.2804 43.5907 29.4499 42.2374 29.4499H30.8589V29.4473ZM35.5505 13.9482H41.4247C42.1385 13.9482 42.76 13.8112 43.2903 13.536C43.8193 13.2608 44.2327 12.8852 44.528 12.4105C44.8233 11.9358 44.971 11.3854 44.971 10.7594C44.971 9.75904 44.6384 8.97652 43.9733 8.4144C43.3082 7.85099 42.335 7.57058 41.0549 7.57058H35.5505V13.9508V13.9482ZM35.5505 25.0561H42.0153C42.9256 25.0561 43.696 24.9191 44.3238 24.6439C44.9517 24.3688 45.4319 23.9632 45.7645 23.4245C46.097 22.8872 46.2626 22.2416 46.2626 21.4917C46.2626 20.7913 46.0842 20.1914 45.7272 19.6906C45.3703 19.1911 44.8593 18.8089 44.1942 18.5455C43.5291 18.2833 42.7407 18.1516 41.8291 18.1516H35.5492V25.0561H35.5505Z"
      fill="#161F26"
    />
    <path
      d="M60.9314 29.8225C59.3303 29.8225 57.8769 29.3726 56.5724 28.4714C55.2666 27.5702 54.2202 26.3455 53.4318 24.7935C52.6434 23.2428 52.2493 21.4782 52.2493 19.5024C52.2493 17.5265 52.6434 15.7632 53.4318 14.2112C54.2202 12.6605 55.291 11.4476 56.6456 10.5711C58.0001 9.69603 59.5268 9.25781 61.2268 9.25781C62.2116 9.25781 63.1103 9.40258 63.9231 9.6895C64.7358 9.97773 65.4497 10.3833 66.066 10.9089C66.681 11.4345 67.1933 12.0345 67.5991 12.71C68.0048 13.3856 68.2821 14.1108 68.4298 14.8868L67.4322 14.6246V9.67125H72.0133V29.4482H67.3949V24.7205L68.4658 24.5327C68.2937 25.2083 67.9791 25.8656 67.5233 26.5033C67.0675 27.1411 66.5013 27.7045 65.8233 28.1923C65.1454 28.6801 64.3943 29.0739 63.57 29.3739C62.7444 29.6739 61.8649 29.8238 60.9289 29.8238L60.9314 29.8225ZM62.1872 25.7325C63.2464 25.7325 64.1824 25.4691 64.9952 24.9448C65.8079 24.4192 66.4422 23.6876 66.898 22.7498C67.3538 21.8121 67.5811 20.7296 67.5811 19.5037C67.5811 18.2777 67.3526 17.2396 66.898 16.3136C66.4422 15.3889 65.8079 14.6624 64.9952 14.1368C64.1824 13.6113 63.2464 13.3491 62.1872 13.3491C61.1279 13.3491 60.1983 13.6113 59.3984 14.1368C58.5972 14.6624 57.9758 15.3889 57.5328 16.3136C57.0898 17.2396 56.8677 18.3025 56.8677 19.5037C56.8677 20.7048 57.0898 21.8121 57.5328 22.7498C57.9758 23.6876 58.5985 24.4192 59.3984 24.9448C60.1983 25.4704 61.1279 25.7325 62.1872 25.7325Z"
      fill="#161F26"
    />
    <path
      d="M75.4504 29.4469V9.67125H79.884L79.9584 13.7247L79.1085 14.1747C79.355 13.2748 79.8352 12.4544 80.5491 11.7162C81.263 10.9781 82.1129 10.3833 83.0977 9.93339C84.0825 9.48344 85.093 9.25781 86.1266 9.25781C87.6045 9.25781 88.8422 9.55778 89.8399 10.159C90.8375 10.759 91.5886 11.6602 92.0932 12.8613C92.5978 14.0625 92.8508 15.5637 92.8508 17.3648V29.4482H88.3068V17.7025C88.3068 16.7022 88.1707 15.8701 87.9011 15.2076C87.6301 14.5451 87.2116 14.0573 86.6453 13.7443C86.0778 13.4313 85.3896 13.2878 84.5769 13.3126C83.9118 13.3126 83.3019 13.4195 82.7485 13.6321C82.1938 13.8447 81.7136 14.1447 81.3079 14.532C80.9022 14.9207 80.5812 15.3641 80.3475 15.8649C80.1125 16.3657 79.997 16.9161 79.997 17.516V29.4495H75.453L75.4504 29.4469Z"
      fill="#161F26"
    />
    <path
      d="M96.1023 29.4469V9.67125H100.536L100.61 13.7247L99.7603 14.1747C100.007 13.2748 100.487 12.4544 101.201 11.7162C101.915 10.9781 102.765 10.3833 103.75 9.93339C104.734 9.48344 105.745 9.25781 106.778 9.25781C108.256 9.25781 109.494 9.55778 110.492 10.159C111.489 10.7603 112.24 11.6602 112.745 12.8613C113.25 14.0625 113.503 15.5637 113.503 17.3648V29.4482H108.959V17.7025C108.959 16.7022 108.823 15.8701 108.553 15.2076C108.282 14.5451 107.863 14.0573 107.297 13.7443C106.73 13.4313 106.041 13.2878 105.229 13.3126C104.564 13.3126 103.954 13.4195 103.4 13.6321C102.846 13.8447 102.365 14.1447 101.96 14.532C101.554 14.9207 101.233 15.3641 100.999 15.8649C100.764 16.3657 100.649 16.9161 100.649 17.516V29.4495H96.1049L96.1023 29.4469Z"
      fill="#161F26"
    />
    <path
      d="M125.879 29.8232C123.835 29.8232 122.031 29.3863 120.468 28.5099C118.904 27.6348 117.684 26.4401 116.81 24.9259C115.935 23.4117 115.497 21.6798 115.497 19.7287C115.497 18.178 115.744 16.7642 116.236 15.4887C116.729 14.2132 117.412 13.1059 118.286 12.1682C119.16 11.2305 120.207 10.504 121.427 9.99147C122.646 9.47891 123.982 9.22198 125.435 9.22198C126.715 9.22198 127.911 9.47239 129.019 9.9719C130.127 10.4727 131.087 11.1535 131.9 12.0169C132.713 12.8803 133.334 13.9067 133.766 15.0935C134.196 16.2817 134.4 17.5767 134.375 18.9774L134.338 20.5907H118.563L117.713 17.4385H130.533L129.942 18.0762V17.2507C129.867 16.476 129.621 15.7939 129.204 15.2057C128.785 14.6175 128.248 14.1545 127.596 13.8167C126.944 13.4789 126.224 13.3094 125.435 13.3094C124.228 13.3094 123.213 13.548 122.387 14.0228C121.561 14.4988 120.94 15.1796 120.521 16.0678C120.103 16.9559 119.894 18.0632 119.894 19.3883C119.894 20.7133 120.158 21.7776 120.688 22.7283C121.217 23.6791 121.969 24.4108 122.942 24.9233C123.915 25.4359 125.054 25.6928 126.358 25.6928C127.269 25.6928 128.107 25.5428 128.871 25.2428C129.634 24.9429 130.459 24.4055 131.347 23.6295L133.6 26.8196C132.935 27.4457 132.177 27.9765 131.327 28.4147C130.477 28.8529 129.585 29.1972 128.649 29.4463C127.713 29.6954 126.789 29.8219 125.878 29.8219L125.879 29.8232Z"
      fill="#161F26"
    />
    <path
      d="M136.556 29.4462V9.67055H141.027L141.1 15.9751L140.473 14.5496C140.743 13.5493 141.211 12.6481 141.876 11.8473C142.541 11.0465 143.31 10.4153 144.184 9.95226C145.059 9.48927 145.977 9.25842 146.937 9.25842C147.356 9.25842 147.756 9.29624 148.138 9.37058C148.519 9.44623 148.834 9.53361 149.08 9.63273L147.862 14.6996C147.591 14.5496 147.258 14.4244 146.864 14.324C146.47 14.2248 146.076 14.174 145.682 14.174C145.065 14.174 144.481 14.2927 143.926 14.53C143.372 14.7687 142.885 15.1 142.467 15.5251C142.048 15.9503 141.722 16.4446 141.487 17.008C141.252 17.5714 141.136 18.2027 141.136 18.903V29.4475H136.555L136.556 29.4462Z"
      fill="#161F26"
    />
  </svg>
);
export default BannerIcon;
