import axios from 'axios';
import { STAGE_DATA } from '../../common/util/env-util';

export function monkeyPatchConsole() {
  if (STAGE_DATA.isDevMode) {
    return;
  }

  const orgError = console.error;
  const orgLog = console.log;

  const reportError = (...args) => {
    try {
      const currentTeamID = sessionStorage.getItem('banner.currentTeamID');
      const currentUserID = sessionStorage.getItem('banner.lastUsedUID');

      const error =
        args?.length === 0
          ? 'No error message'
          : args
              .map((a) => {
                if (typeof a === 'object') {
                  if (a?.message && a?.stack) return JSON.stringify({ message: a.message, stack: a.stack });
                  return JSON.stringify(a);
                }
                return a;
              })
              .join(' ');

      axios
        .post('/api/reportClientError', {
          currentTeamID,
          currentUserID,
          error,
        })
        .catch((err) => {
          orgError('ERROR in reportError axios.post:', err);
        });
    } catch (e) {
      orgError('ERROR in reportError:', e);
    }
  };

  const error = (...args) => {
    reportError(...args);

    orgError(...args);
  };

  const log = (...args) => {
    if (
      typeof args[0] === Error ||
      (typeof args[0] === 'object' && args[0] !== null && args[0].message && args[0].stack)
    ) {
      reportError(...args);
    }

    orgLog(...args);
  };

  console.error = error;
  console.log = log;
}
