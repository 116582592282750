/**
 * @param {string | null | undefined} email
 * @returns {string} trimmed and lowercased email
 */
function getCleanEmail(email) {
  return email?.trim()?.toLowerCase() || '';
}

/**
 * @param {string | null | undefined} email
 * @returns {boolean} TRUE if it is the email of a Banner employee
 */
function isBannerEmail(email) {
  return (email || '').endsWith('@withbanner.com');
}

/**
 * @param {{email: string, individualName: string} | null | undefined } manager
 * @returns {boolean} TRUE if manager is "Banner Admin"
 */
function isBannerAdmin(manager) {
  const { email, individualName } = manager || {};
  return isBannerEmail(email) && individualName === 'Banner Admin';
}

function getManagersForShellEmails(shell, projects = []) {
  const set = new Set();

  const { managerID } = shell || {};
  if (managerID) {
    set.add(managerID);
  }
  for (const project of projects) {
    const projectSet = getManagersForProjectEmails(project);
    for (const id of projectSet) {
      set.add(id);
    }
  }

  return set;
}

function getManagersForProjectEmails(project) {
  const set = new Set();

  const { managerID, notifications } = project || {};
  if (managerID) {
    set.add(managerID);
  }
  (notifications?.managers || []).forEach((id) => set.add(id));

  return set;
}

module.exports = {
  getCleanEmail,
  isBannerEmail,
  isBannerAdmin,
  getManagersForShellEmails,
  getManagersForProjectEmails,
};
