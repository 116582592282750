import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../theme/theme';
import { LinkButtonMixin } from '../ButtonLink/ButtonLink';
export var ButtonVariants;
(function (ButtonVariants) {
  ButtonVariants['SOLID'] = 'SOLID';
  ButtonVariants['TEXT'] = 'TEXT';
})(ButtonVariants || (ButtonVariants = {}));
export const VariantStyles = {
  [ButtonVariants.SOLID]: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    transition: ${theme.transitions.transition()};
    padding: 18px 35px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 19px;
    text-align: center;
    color: ${theme.colors.White};
    background-color: ${(p) =>
      p.disabled ? `${theme.colors['Paper White']}` : `${theme.colors['Deep Sea Coral']}`};
    width: 100%;

    &:hover {
      background-color: ${(p) =>
        p.disabled ? `${theme.colors['Paper White']}` : `${theme.colors['Deep Sea Coral Hover']}`};
    }
    &:active {
      background-color: ${(p) =>
        p.disabled ? `${theme.colors['Paper White']}` : `${theme.colors['Deep Sea Coral Pressed']}`};
    }

    @media screen and (min-width: ${theme.breakpoints[768]}) {
      width: unset;
    }

    @media screen and (min-width: ${theme.breakpoints[1440]}) {
      padding: 24px 40px;
    }
  `,
  [ButtonVariants.TEXT]: css`
    ${LinkButtonMixin}
  `,
};
const StyledWrapper = styled.button`
  ${(p) => VariantStyles[p.variant || ButtonVariants.SOLID]}
`;
function Button({ disabled, children, className, onClick, ...rest }) {
  return (
    <StyledWrapper {...rest} disabled={disabled} className={className} onClick={onClick}>
      {children}
    </StyledWrapper>
  );
}
export default Button;
