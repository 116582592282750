import React from 'react';
import { forwardRef, memo } from 'react';
const SolidPlayIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={26}
    height={30}
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M24.7 12.865c1.333.77 1.333 2.694 0 3.464L3.01 28.854c-1.333.77-3-.192-3-1.732V2.072c0-1.54 1.667-2.502 3-1.732L24.7 12.865Z"
      fill="#2D4459"
    />
  </svg>
);
const ForwardRef = forwardRef(SolidPlayIcon);
const Memo = memo(ForwardRef);
export default Memo;
