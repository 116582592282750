import React, { forwardRef, memo } from 'react';

function LongArrow({ title, titleId, ...props }, ref) {
  return (
    <svg
      width={80}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M71.293.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 1 1-1.414-1.414L76.586 9H1a1 1 0 1 1 0-2h75.586l-5.293-5.293a1 1 0 0 1 0-1.414Z"
        fill="#F25E53"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(LongArrow);
const Memo = memo(ForwardRef);
export default Memo;
