import React from 'react';
import { forwardRef, memo } from 'react';
const PauseIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={40}
    height={39}
    viewBox="0 0 40 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.716 5.145h6.228v29.06H9.716V5.145ZM24.246 5.145h6.227v29.06h-6.227V5.145Z"
      stroke="#fff"
      strokeWidth={2.77}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(PauseIcon);
const Memo = memo(ForwardRef);
export default Memo;
