import React from 'react';
import styled from 'styled-components';
import { InstagramIcon, LinkedinIcon } from '../../Icons';
import SVGSocialLink from './SVGSocialLink';

const StyledSocialMedia = styled.div`
  gap: 42px;
  display: flex;
`;
function SocialMedia({ className }) {
  return (
    <StyledSocialMedia className={className}>
      {/* <SVGSocialLink to="">
        <InstagramIcon />
      </SVGSocialLink> */}
      <SVGSocialLink
        to={{ pathname: 'https://www.linkedin.com/company/withbanner' }}
        target="_blank"
        aria-label="linkedin"
      >
        <LinkedinIcon />
      </SVGSocialLink>
    </StyledSocialMedia>
  );
}
export default SocialMedia;
