import React from 'react';
import styled from 'styled-components';
import config from '../../../../config';
import theme from '../../../../theme/theme';
import IconsGrid from '../../common/IconsGrid';

const StyledIconsGrid = styled(IconsGrid)`
  margin-top: 72px;

  .inner-icons-grid {
    justify-content: space-around;
    align-items: center;
    row-gap: 25px;
    column-gap: 40px;
  }

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 100px;

    .inner-icons-grid {
      row-gap: 78px;
      column-gap: 16px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    .inner-icons-grid {
      column-gap: 32px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    .inner-icons-grid {
      column-gap: 50px;
      row-gap: 62px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    .inner-icons-grid {
      column-gap: 104px;
      row-gap: 62px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 80px;

    .inner-icons-grid {
      column-gap: 135px;
    }
  }
`;
const StyledImage = styled.img`
  width: 150px;
  height: 60px;
  object-fit: contain;

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    width: 200px;
    height: 80px;
  }
`;
const CUSTOMERS = [
  'bc07f7f5-06e3-47e0-dea1-d40c3b4d8a00/public',
  '6a7d527a-c943-4714-8aa9-7b4ab4caa800/public',
  '133e6c2d-9dda-4935-42d6-9959286d1f00/public',
  '72cb3957-2f27-4f9b-d01c-e49bd48ce200/public',
  '26ef8826-aa50-4549-cfda-8eb491f96900/public',
  '71ee31b5-d0f2-4f3c-3252-42a00d54c100/public',
  'b4b97b99-34b5-4d12-188a-eb384b04f200/public',
  'f0ac3d49-2efb-4d36-a5e8-b42f720e1300/public',
];
function CustomersSection() {
  return (
    <StyledIconsGrid tagline="Our customers">
      {CUSTOMERS.map((customer, i) => (
        <StyledImage
          data-aos="fade-up"
          data-aos-delay={`${i * 50}`}
          key={customer}
          src={`${config.CLOUDFLARE_FULL_URL}/${customer}`}
        />
      ))}
    </StyledIconsGrid>
  );
}
export default CustomersSection;
