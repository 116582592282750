import constate from 'constate';
import { useEffect, useState } from 'react';
const useSopportWebpAvifContext = () => {
  const [supported, setSupported] = useState(null);
  useEffect(() => {
    const checkWebpSupport = new Promise((res) => {
      const image = new Image();
      image.addEventListener('error', () => res(false));
      image.addEventListener('load', () => res(image.width === 1 ? 'webp' : false));
      image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
    });
    const checkAvifSupport = new Promise((res) => {
      const image = new Image();
      image.addEventListener('error', () => res(false));
      image.addEventListener('load', () => res(image.width === 1 ? 'avif' : false));
      image.src =
        'data:image/avif;base64,AAAAFGZ0eXBhdmlmAAAAAG1pZjEAAACgbWV0YQAAAAAAAAAOcGl0bQAAAAAAAQAAAB5pbG9jAAAAAEQAAAEAAQAAAAEAAAC8AAAAGwAAACNpaW5mAAAAAAABAAAAFWluZmUCAAAAAAEAAGF2MDEAAAAARWlwcnAAAAAoaXBjbwAAABRpc3BlAAAAAAAAAAQAAAAEAAAADGF2MUOBAAAAAAAAFWlwbWEAAAAAAAAAAQABAgECAAAAI21kYXQSAAoIP8R8hAQ0BUAyDWeeUy0JG+QAACANEkA=';
    });
    const checker = async () => {
      const [webp, avif] = await Promise.all([checkWebpSupport, checkAvifSupport]);
      setSupported(webp || avif || 'none');
    };
    checker();
  }, []);
  return { supported };
};
export const [SopportWebpAvifProvider, useSopportWebpAvif] = constate(useSopportWebpAvifContext);
