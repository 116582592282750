import React from 'react';
import { forwardRef, memo } from 'react';
const MinimizeIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={24}
    height={23}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.637 7.83h2.768A1.845 1.845 0 0 0 8.25 5.984V3.216M15.631 3.216v2.768a1.845 1.845 0 0 0 1.846 1.846h2.768M8.25 19.825v-2.769a1.845 1.845 0 0 0-1.845-1.845H3.637M20.245 15.211h-2.768a1.845 1.845 0 0 0-1.846 1.845v2.768"
      stroke="#fff"
      strokeWidth={2.076}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(MinimizeIcon);
const Memo = memo(ForwardRef);
export default Memo;
