import React from 'react';
import { forwardRef, memo } from 'react';
const MoneyIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.15 14a2 2 0 0 1 2-2h62.7a2 2 0 0 1 2 2v32.67a2 2 0 0 1-2 2H8.15a2 2 0 0 1-2-2V14ZM5 57.83h69M5 67h69"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.5 39.5c5.082 0 9.202-4.105 9.202-9.17 0-5.064-4.12-9.169-9.202-9.169s-9.202 4.105-9.202 9.17c0 5.064 4.12 9.169 9.202 9.169Z"
      fill="#F25E53"
    />
    <path
      d="M16.503 21.17h4.6M57.905 39.5h4.601"
      stroke="#F25E53"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(MoneyIcon);
const Memo = memo(ForwardRef);
export default Memo;
