import React, { useLayoutEffect } from 'react';
import Head from '../components/_base/layout-util/react-portals/Head';
import RouteWrapper from '../landing/components/common/RouteWrapper';
import IndexPage from '../landing/pages/index';

export default function Landing() {
  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-landing', '');
    return () => document.documentElement.removeAttribute('data-landing');
  }, []);

  return (
    <RouteWrapper>
      <Head>
        <meta name="theme-color" content="#2d4459" />
      </Head>
      <IndexPage />
    </RouteWrapper>
  );
}
