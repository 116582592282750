import React from 'react';
import styled, { css } from 'styled-components';
import Navbar from '../../../common/Navbar';
import useNavbar from '../../../../hooks/useNavbar';
import theme from '../../../../theme/theme';

const StyledWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 80;

  .navbar-mobile {
    padding: ${theme.paddings.container.xs} 0;
  }

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    .navbar-mobile {
      padding: ${theme.paddings.container.sm} 0;
    }
  }

  ${({ isNavbarOpen }) =>
    isNavbarOpen &&
    css`
      height: 100vh;
    `}
`;
function LandingHeader() {
  const { isNavbarOpen, setIsNavbarOpen } = useNavbar();
  return (
    <StyledWrapper isNavbarOpen={isNavbarOpen}>
      <Navbar isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />
    </StyledWrapper>
  );
}
export default LandingHeader;
