const stylesCancelButton = /** @type {const} */ ({
  textTransform: 'none',
});

const stylesDefaultButtonCommon = /** @type {const} */ ({
  boxShadow: 'none',
  textTransform: 'none',
  fontWeight: 700,
  borderRadius: '3px',
  width: 'auto',
  paddingLeft: '16px',
  paddingRight: '16px',
  // height: "36px",
  // marginLeft: "10px",
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});

export const stylesDefaultBlueButton = /** @type {const} */ ({
  ...stylesDefaultButtonCommon,
  backgroundColor: '#2a7de1',
  borderColor: '#2a7de1',
  color: 'white',
  '&:hover': {
    ...stylesDefaultButtonCommon['&:hover'],
    backgroundColor: '#2a7de1',
    borderColor: '#2a7de1',
  },
  '&:active': {
    ...stylesDefaultButtonCommon['&:active'],
    backgroundColor: '#2a7de1',
    borderColor: '#2a7de1',
  },
});

export const stylesDefaultRedButton = /** @type {const} */ ({
  ...stylesDefaultButtonCommon,
  backgroundColor: '#d32f2f',
  borderColor: '#d32f2f',
  color: 'white',
  '&:hover': {
    ...stylesDefaultButtonCommon['&:hover'],
    backgroundColor: '#d32f2f',
    borderColor: '#d32f2f',
  },
  '&:active': {
    ...stylesDefaultButtonCommon['&:active'],
    backgroundColor: '#d32f2f',
    borderColor: '#d32f2f',
  },
});

export const stylesDefaultGreyButton = /** @type {const} */ ({
  ...stylesDefaultButtonCommon,
  backgroundColor: '#a6a6a6',
  borderColor: '#a6a6a6',
  color: 'white',
  '&:hover': {
    ...stylesDefaultButtonCommon['&:hover'],
    backgroundColor: '#a6a6a6',
    borderColor: '#a6a6a6',
  },
  '&:active': {
    ...stylesDefaultButtonCommon['&:active'],
    backgroundColor: '#a6a6a6',
    borderColor: '#a6a6a6',
  },
});

export const stylesDefaultSecondaryButton = /** @type {const} */ ({
  ...stylesDefaultBlueButton,
  backgroundColor: 'white',
  border: 'var(--border)',
  borderColor: '#2a7de1',
  color: stylesDefaultBlueButton.backgroundColor,
  '&:hover': {
    ...stylesDefaultBlueButton['&:hover'],
    backgroundColor: 'white',
    color: stylesDefaultBlueButton['&:hover'].backgroundColor,
  },
  '&:active': {
    ...stylesDefaultBlueButton['&:active'],
    backgroundColor: 'white',
    color: stylesDefaultBlueButton['&:active'].backgroundColor,
  },
});

export const stylesDefaultDisabledButton = /** @type {const} */ ({
  ...stylesDefaultButtonCommon,
  color: 'rgba(0, 0, 0, 0.26)',
  background: 'rgba(0, 0, 0, 0.12)',
  '&:hover': {
    ...stylesDefaultButtonCommon['&:hover'],
    color: 'rgba(0, 0, 0, 0.26)',
    background: 'rgba(0, 0, 0, 0.12)',
  },
});

export const colorMassEditPendingEdit = 'yellow';
