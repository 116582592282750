import React from 'react';
import { forwardRef, memo } from 'react';
const CrossIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={27}
    height={26}
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.88 25 25.897 1M25.897 25 1.88 1"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(CrossIcon);
const Memo = memo(ForwardRef);
export default Memo;
