import { isEqual } from 'lodash';
import type { FunctionComponent, MutableRefObject } from 'react';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { STAGE_DATA } from '../../common/util/env-util';
import type { IsTypeAny } from '../../common/util/type-util-IsAny';
import { tsAssumeMemoized } from '../../common/util/types/ts-assume';
import { useLogEveryDepChange } from './dev-util';

const DEV_MODE = process.env.NODE_ENV === 'development';

export type RemoveMemoizedTag<T> = T extends string | number | boolean | null | undefined
  ? T
  : Omit<T, '_ts_hint_memoized'>;

/**
 * Applies phantom type `{ _ts_hint_memoized: true }` to a single value.
 * This is useful to require certain params to be memoized
 * before passing it as one of the deps of `useMemo` / `useCallback` / `useEffect`, etc.
 * That is, any time you need to attempt referential equality.
 *
 * The exception is any scalar value, (for example `Memoized<string>` is just the same as normal `string`)
 * because, referential equality is not required in that case.
 *
 * This follows the implementation of memoizing deps, which use `===` comparison.
 * `'some string' === 'some string'`, but `{} !== {}`.
 *
 * @see {@link useMemoWithTypeCheck}
 * @see {@link MemoizedProps}
 * @see {@link MemoizedArgs}
 */
export type Memoized<T> = T extends string | number | boolean | null | undefined
  ? T
  : T & { _ts_hint_memoized: true };

/** refers to a value that is not referentially equal but is being re-constructed on every render */
export type NotMemoized<T> = T extends { _ts_hint_memoized: any } ? Omit<T, '_ts_hint_memoized'> : T;

/**
 * Applies phantom type `{ _ts_hint_memoized: true }` to all values of an object.
 * (without any assumptions about the object itself)
 *
 * @see {@link tsChildrenMemoizedBecauseParentMemoized} If you need to asser the outer object is memoized first.
 * @see {@link MemoizedArgs} for arrays
 */
export type MemoizedProps<T extends Record<any, any>> = { [K in keyof T]: Memoized<T[K]> };

/**
 * Applies phantom type `{ _ts_hint_memoized: true }` to all values of an array.
 * (without any assumptions about the array itself)
 *
 * @see {@link MemoizedProps} for objects
 */
export type MemoizedArgs<T extends readonly any[]> = readonly any[] & {
  [K in keyof T & `${number}`]: IsTypeAny<T[K]> extends true ? Memoized<unknown> : Memoized<T[K]>;
};

/**
 * Helps prevent `useMemo` from silently breaking by raising a type error
 * if any of the deps fail to be properly memoized due to missing phantom type `{ _ts_hint_memoized: true }`.
 *
 * @see {@link Memoized}
 */
export function useMemoWithTypeCheck<Fn extends (...args: any) => any>(
  fn: Fn,
  args: MemoizedArgs<Parameters<Fn>>,
  devModeOptions?: Parameters<typeof useMemoTypical>[2],
): Memoized<ReturnType<Fn>> {
  return tsAssumeMemoized(useMemoTypical(fn, args as Parameters<Fn>, devModeOptions));
}

/**
 * Similar to {@link useMemoWithTypeCheck} but is less strict.
 * Rather than raising a type error if one of the deps is not {@link Memoized},
 * it will simply return a normal `useMemo` result without the `_ts_hint_memoized` phantom type.
 */
export function useMemoTypical<Fn extends (...args: any) => any, Args extends Parameters<Fn>>(
  fn: Fn,
  args: Args,
  devModeOptions?: { useLogEveryDepChange?: true },
): Args extends MemoizedArgs<Parameters<Fn>> ? Memoized<ReturnType<Fn>> : ReturnType<Fn> {
  if (STAGE_DATA.isTesting) {
    const initialFn = useRef<typeof fn>(); // eslint-disable-line react-hooks/rules-of-hooks -- DEV_MODE is constant so condition is fine
    // eslint-disable-next-line react-hooks/rules-of-hooks -- DEV_MODE is constant so condition is fine
    useEffect(() => {
      initialFn.current = fn;
      // using useEffect instead of useSingleton so we don't get unnecessary/incorrect warnings when using React HMR in dev mode
    }, []); // eslint-disable-line react-hooks/exhaustive-deps -- empty deps array is intentional

    const errWithGoodStack = new Error(
      'fn is not the same as the initial render. Is this a bug? The fn should be a non-nested function',
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks -- DEV_MODE is constant so condition is fine
    useEffect(() => {
      if (initialFn.current !== fn) {
        console.error(errWithGoodStack);
      }
    }); // no deps array on this one
  }
  if (devModeOptions?.useLogEveryDepChange) {
    useLogEveryDepChange({ [fn.name]: args }); // eslint-disable-line react-hooks/rules-of-hooks -- devModeOptions.useLogEveryDepChange is temporary and constant
  }
  return useMemo(() => fn(...(args as readonly any[])), args);
}

/**
 * (Does nothing at runtime.)
 *
 * Shallowly apply phantom type to children objects.
 *
 * Converts example type:
 * ```
 * { a: something, b: something } & { _ts_hint_memoized: true }
 * ```
 * to
 * ```
 * {
 *   a: something & { _ts_hint_memoized: true },
 *   b: something & { _ts_hint_memoized: true },
 * } & { _ts_hint_memoized: true }
 * ```
 */
export function tsChildrenMemoizedBecauseParentMemoized<T>(x: Memoized<T>) {
  return x as T extends Record<any, any> ? MemoizedProps<T> : Memoized<T>;
}

/** drop-in replacement for `React.memo` that attempts to require each prop to be memoized in the type checks */
export function memoWithTypeCheck<Props extends Record<string, any>>(Component: FunctionComponent<Props>) {
  return memo(Component as FunctionComponent<MemoizedProps<Props>>);
}

/**
 * @example
 * // before
 * const contextVal = useMemo(() => ({ something, somethingElse, etc }), [ something, somethingElse, etc ]);
 * // after
 * const contextVal = useMakeContextWithTypeCheck({ something, somethingElse, etc });
 */
export function useMakeContextWithTypeCheck<T extends Record<string, any>>(contextVal: MemoizedProps<T>) {
  return useMakeContextTypical(contextVal);
}

/**
 * Similar to {@link useMakeContextWithTypeCheck} but is less strict.
 * Rather than raising a type error if one of the deps is not {@link Memoized},
 * it will simply return a normal `useMemo` result without the `_ts_hint_memoized` phantom type.
 *
 * @example
 * // before
 * const contextVal = useMemo(() => ({ something, somethingElse, etc }), [ something, somethingElse, etc ]);
 * // after
 * const contextVal = useMakeContextTypical({ something, somethingElse, etc });
 */
export function useMakeContextTypical<T extends Record<string, any>>(contextVal: T) {
  type ContextVal = T extends MemoizedProps<T> ? Memoized<T> : T;
  return useMemo(() => contextVal, Object.values(contextVal)) as ContextVal;
}

/**
 * This is like running `useMemoTypical` on each row individually.
 * Useful if you need referential equality of unchanged rows
 * especially for `MaterialTable` which relies upon referential equality and index-based keys
 * instead of a proper key-based design.
 */
export function useMemoByIndividualRow<
  SrcArray extends readonly any[],
  TransformFn extends (...args: any[]) => any,
>(
  srcArray: SrcArray,
  transformFn: TransformFn,
  mapSpecFn: (
    srcRow: SrcArray[number],
    index: number,
  ) => {
    key: any;
    memoDeps: Readonly<Parameters<TransformFn>>;
  },
) {
  if (DEV_MODE) {
    const initialTransformFn = useSingleton(() => transformFn);
    if (initialTransformFn !== transformFn) {
      console.error(
        new Error(
          'transformFn is not the same as the initial render. Is this a bug? ' +
            'The transformFn should be a non-nested function',
        ),
      );
    }
  }

  const lastRendered = useSingleton(() => new Map<any, any>());
  const keysFoundThisRender = new Set<any>();
  const result = srcArray.map((srcRow, index) => {
    const { key, memoDeps } = mapSpecFn(srcRow, index);
    keysFoundThisRender.add(key);
    const lastRenderedRow = lastRendered.get(key);
    if (lastRenderedRow && allDepsSame(lastRenderedRow.memoDeps, memoDeps)) {
      return lastRenderedRow.result;
    }
    const result = transformFn(...memoDeps);
    lastRendered.set(key, { memoDeps, result });
    return result;
  });

  for (const key of lastRendered.keys()) {
    if (!keysFoundThisRender.has(key)) {
      lastRendered.delete(key);
    }
  }

  return result;
}

function allDepsSame(a: readonly any[], b: readonly any[]) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
  return true;
}

type AnythingExceptUndefined = string | number | boolean | null | object;

export function useDeepEqualToReferentiallyEqual<T>(val: T) {
  const ref = useRef(val);
  if (!isEqual(ref.current, val)) ref.current = val;
  return tsAssumeMemoized(ref.current);
}

export function useSingleton<InitialValue extends AnythingExceptUndefined>(
  makeInitialValue: () => InitialValue,
) {
  const ref = useRef<InitialValue>(undefined as unknown as InitialValue);
  if (ref.current === undefined) ref.current = makeInitialValue();
  return ref.current;
}

export function useAlwaysLatest<T>(latestValue: T) {
  const ref = useRef(latestValue);
  ref.current = latestValue;
  return ref;
}

export function useProxyUsingRef<LatestFn extends (...args: readonly any[]) => any>(latestFn: LatestFn) {
  const ref = useAlwaysLatest(latestFn);
  return useCallback(_makeNewProxyToRef(ref), []);
}

function _makeNewProxyToRef<LatestFn extends (...args: readonly any[]) => any>(
  ref: MutableRefObject<LatestFn>,
) {
  return ((...args) => ref.current(...args)) as NotMemoized<typeof ref.current>;
}
