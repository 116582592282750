import React from 'react';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import ButtonLink from '../../../../common/ButtonLink';
import P from '../../../../common/P';
import { ParagraphVariants } from '../../../../common/P/P';
import theme from '../../../../../theme/theme';

const StyledWrapper = styled.div`
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: ${theme.colors['Anti Flash White']};
  user-select: none;
  display: flex;
  flex-direction: column;
`;
const StyledImageWrapper = styled.div`
  width: 100%;
  height: 307px;
  position: relative;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
const StyledCategory = styled.h4`
  padding: 8px 24px;
  border-radius: 100px 0 0 100px;
  position: absolute;
  top: 25px;
  right: 0;
  color: ${theme.colors.White};
  background: ${theme.colors.Mindnight};
  font-weight: 300;
`;
const StyledBodyWrapper = styled.div`
  padding: 32px 40px 48px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1;
`;
const StyledTitle = styled.h3`
  color: ${theme.colors['Moonlit Ocean']};
`;
const StyledDescription = styled(P)`
  flex: 1;
  color: ${theme.colors.Mindnight};
`;
const StyledButtonLink = styled(ButtonLink)`
  font-weight: 600;
  width: fit-content;
`;
function CaseStudyCard({ image, category, title, description, id, slug, ...rest }) {
  return (
    <StyledWrapper {...rest}>
      <StyledImageWrapper>
        <StyledImage src={image} />
        <StyledCategory data-aos="fade-left" data-aos-delay={Number(rest['data-aos-delay'] || 0) + 50}>
          {capitalize(category)}
        </StyledCategory>
      </StyledImageWrapper>
      <StyledBodyWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription variant={ParagraphVariants.SECONDARY}>{description}</StyledDescription>
        <StyledButtonLink to={`/case-studies/${slug}`}>View case study {'->'}</StyledButtonLink>
      </StyledBodyWrapper>
    </StyledWrapper>
  );
}
export default CaseStudyCard;
