import React from 'react';
import styled from 'styled-components';

const StyledText = styled.h4`
  color: ${({ theme }) => theme.colors['Deep Sea Coral']};
  text-transform: uppercase;
  font-weight: 300;
`;
function Tagline({ text, className, ...rest }) {
  return (
    <StyledText className={className} {...rest}>
      {text}
    </StyledText>
  );
}
export default Tagline;
