import deepmerge from 'deepmerge';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import BookADemoButton from '../../../common/BookADemoButton';
import P from '../../../common/P';
import { ParagraphVariants } from '../../../common/P/P';
import Video from '../../../common/Video';
import config from '../../../../config';
import { LayoutMixin } from '../../../../theme';
import theme from '../../../../theme/theme';

const StyledWrapper = styled.div`
  position: relative;
  margin-top: 72px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 100px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 88px;
  }
`;
const StyledVideoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    ${LayoutMixin}
    flex-direction: unset;

    ${({ isFullWidth }) =>
      isFullWidth &&
      css`
        .video-wrapper {
          width: 100%;
        }

        .video-overlay {
          visibility: hidden;
        }
      `}
  }
`;
const StyledVideo = styled(Video)`
  height: 400px;
  width: 100%;
  border-radius: unset;

  video {
    object-fit: cover;
  }

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: 617px;
    height: 346px;
    border-radius: 16px;

    &[data-aos='fade-up'] {
      transition:
        opacity,
        transform,
        width 0.2s;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    width: 658px;
    height: 410px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    width: 824px;
    height: 500px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    width: 987px;
    height: 620px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    width: 1016px;
    height: 630px;
  }
`;
const StyledOverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    ${LayoutMixin};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
`;
const StyledOverlay = styled.div`
  background-color: ${transparentize(0.03, theme.colors['Anti Flash White'])};
  padding: 24px 16px 40px;
  width: 100%;
  pointer-events: all;

  &[data-aos='fade-out-right'].aos-animate {
    transition-duration: unset !important;
  }

  .overlay-book-a-demo-button {
    width: fit-content;
  }

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    position: absolute;
    width: 328px;
    right: ${theme.paddings.container['xs+']};
    top: 50%;
    transform: translateY(-50%);
    border-radius: 8px;
    padding: 40px 24px;

    &[data-aos='fade-left'] {
      opacity: 0;
      transform: translate3d(100px, -50%, 0);

      &.aos-animate {
        opacity: 1;
        transform: translate3d(0px, -50%, 0);
      }
    }

    &[data-aos='fade-out-right'] {
      opacity: 1;
      transform: translate3d(0, -50%, 0);

      &.aos-animate {
        opacity: 0;
        transform: translate3d(100px, -50%, 0);
      }
    }
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    width: 344px;
    padding: 40px 32px;
    right: ${theme.paddings.container.sm};
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    width: 392px;
    right: ${theme.paddings.container['sm+']};
    padding: 56px;
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    right: ${theme.paddings.container.md};
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    width: 392px;
    right: ${theme.paddings.container.xl};
    padding: 48px;
  }
`;
const StyledOverlayInnerWrapper = styled.div`
  ${LayoutMixin};
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    max-width: unset;
    padding: unset;
    margin: unset;
  }
`;
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
const StyledBookADemoButton = styled(BookADemoButton)`
  max-width: 343px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    max-width: unset;
  }
`;
function VideoSection() {
  const [isFullWidth, setIsFullWidth] = useState(false);
  const handleOnClickPlay = ({ handlePlayPause }) => {
    handlePlayPause();
    setIsFullWidth(true);
  };
  const handleOnClickClose = ({ controls, setControls, handleFullscreen }) => {
    if (controls.fullScreen) handleFullscreen();
    setControls(deepmerge(controls, { playing: false }));
    setIsFullWidth(false);
  };
  return (
    <StyledWrapper>
      <StyledVideoWrapper isFullWidth={isFullWidth}>
        <StyledVideo
          data-aos="fade-up"
          className="video-wrapper"
          url={`https://banner-web-assets.nyc3.cdn.digitaloceanspaces.com/videos/Tourmaline%20Testimonial_FINAL.mp4`}
          width="100%"
          height="100%"
          onClickPlay={!isFullWidth ? handleOnClickPlay : undefined}
          onClickClose={handleOnClickClose}
          hideControls={!isFullWidth}
          hideCloseButton={!isFullWidth}
        />
      </StyledVideoWrapper>
      <StyledOverlayWrapper>
        <StyledOverlay
          className="video-overlay"
          data-aos={!isFullWidth ? 'fade-left' : 'fade-out-right'}
          data-aos-delay={!isFullWidth ? '100' : '0'}
          data-aos-duration={!isFullWidth ? '600' : '200'}
        >
          <StyledOverlayInnerWrapper>
            <StyledTextWrapper>
              <h2 data-aos="fade-up" data-aos-delay="150" data-aos-offset="72">
                Hear it from your peers
              </h2>
              <P
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-offset="8"
                variant={ParagraphVariants.SECONDARY}
              >
                We work with our clients to solve their biggest pain points.
              </P>
            </StyledTextWrapper>
            <StyledBookADemoButton
              className="overlay-book-a-demo-button"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-offset="-88"
            />
          </StyledOverlayInnerWrapper>
        </StyledOverlay>
      </StyledOverlayWrapper>
    </StyledWrapper>
  );
}
export default VideoSection;
