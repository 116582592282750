import React from 'react';
import { forwardRef, memo } from 'react';
const DatabaseIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M25.2 17.061c12.813 0 23.2-3.595 23.2-8.03C48.4 4.595 38.013 1 25.2 1 12.387 1 2 4.596 2 9.03c0 4.436 10.387 8.031 23.2 8.031Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.03v37.478c0 4.461 10.35 8.03 23.2 8.03s23.2-3.569 23.2-8.03V9.03"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.4 21.523c0 4.462-10.35 8.031-23.2 8.031S2 25.984 2 21.524m46.4 12.492c0 4.461-10.35 8.03-23.2 8.03S2 38.477 2 34.016"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={45} cy={45} r={13} fill="#F25E53" />
    <path
      d="M48.18 42.205a3.039 3.039 0 0 0-1.02-1.806 2.97 2.97 0 0 0-1.928-.716c-1.65 0-2.988 1.103-2.988 2.774s1.494 2.428 2.988 2.775c1.494.346 2.988 1.103 2.988 2.774 0 1.672-1.337 2.775-2.988 2.775a2.968 2.968 0 0 1-2.113-.886 3.046 3.046 0 0 1-.875-2.14M45.66 39.035v-1.913m0 16.22v-1.914"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(DatabaseIcon);
const Memo = memo(ForwardRef);
export default Memo;
