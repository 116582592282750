import React from 'react';
import { forwardRef, memo } from 'react';
const PlayIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={40}
    height={39}
    viewBox="0 0 40 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.789 4.988 31.394 19.52 8.79 34.052V4.988Z"
      stroke="#fff"
      strokeWidth={2.768}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(PlayIcon);
const Memo = memo(ForwardRef);
export default Memo;
