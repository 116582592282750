import React from 'react';
import { forwardRef, memo } from 'react';
const ReportIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 56.41V14.051c0-.951.374-1.865 1.039-2.538a3.526 3.526 0 0 1 2.509-1.052H21v1.436h-4.452c-.565 0-1.106.227-1.505.631-.4.404-.624.951-.624 1.523v42.36c0 .57.224 1.118.623 1.522.4.404.94.631 1.506.631h31.22c.565 0 1.106-.227 1.505-.63.4-.405.624-.952.624-1.524V14.051c0-.572-.225-1.12-.624-1.523-.399-.404-.94-.63-1.505-.63H43V10.46h4.768c.94 0 1.844.379 2.509 1.052a3.611 3.611 0 0 1 1.039 2.538v42.36c0 .95-.374 1.864-1.04 2.538a3.526 3.526 0 0 1-2.508 1.05h-31.22c-.94 0-1.844-.377-2.509-1.05A3.611 3.611 0 0 1 13 56.41Z"
      fill="#2D4459"
    />
    <path
      d="M43 9.867V12.8a2.202 2.202 0 0 1-2.2 2.2H23.2a2.2 2.2 0 0 1-2.2-2.2V9.867a2.202 2.202 0 0 1 2.2-2.2h5.133a3.668 3.668 0 0 1 5.5-3.176 3.668 3.668 0 0 1 1.834 3.176H40.8a2.2 2.2 0 0 1 2.2 2.2Z"
      fill="#F25E53"
    />
    <path
      d="M21 33h7.636M21 39.333h21m-21 6.334h21M21 52h17.182"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(ReportIcon);
const Memo = memo(ForwardRef);
export default Memo;
