import React from 'react';
import { forwardRef, memo } from 'react';
const MaximizeIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={24}
    height={23}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.252 3.216H5.484a1.845 1.845 0 0 0-1.846 1.846V7.83m16.608 0V5.062a1.845 1.845 0 0 0-1.845-1.846h-2.768m0 16.608H18.4a1.845 1.845 0 0 0 1.845-1.845v-2.768m-16.608 0v2.768a1.845 1.845 0 0 0 1.846 1.845h2.768"
      stroke="#fff"
      strokeWidth={2.076}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(MaximizeIcon);
const Memo = memo(ForwardRef);
export default Memo;
