import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from '../../../theme/theme';

export const SVGSocialMixin = css`
  > svg > path {
    transition: ${theme.transitions.transition()};
  }

  &:hover > svg > path {
    fill: ${theme.colors['Deep Sea Coral Hover']};
  }
`;
const StyledLink = styled(Link)`
  ${SVGSocialMixin}
`;
function SVGSocialLink(props) {
  return <StyledLink {...props} />;
}
export default SVGSocialLink;
