import React from 'react';
import { rgba } from 'polished';
import Slider from 'rc-slider';
import styled, { css } from 'styled-components';
import theme from '../../../theme/theme';

const StyledWrapper = styled.div`
  width: 100%;

  .rc-slider-track {
    cursor: pointer;
    background: ${theme.colors.White};
    border-radius: 7.61px;
  }

  .rc-slider-rail {
    cursor: pointer;
    height: 4.15px;
    border-radius: 7.61px;
    background: ${rgba(theme.colors.White, 0.2)};
  }

  .rc-slider-handle {
    cursor: pointer;
    border: unset;
    opacity: unset;
    margin-top: -2px;
    width: 8.32px;
    height: 8.32px;
    border-radius: 100px;
    background: ${theme.colors.White};

    &:active {
      box-shadow: unset;
    }

    &.rc-slider-handle-dragging {
      cursor: grabbing;
      border: unset;
      box-shadow: unset;
    }

    ${({ withScrubber }) =>
      !withScrubber &&
      css`
        opacity: 0;
      `}

    @media screen and (min-width: ${theme.breakpoints['1440']}) {
      margin-top: -6px;
      width: 16.61px;
      height: 16.61px;
    }
  }
`;
function ProgressBar({ className, progress, withScrubber = false, onChange }) {
  return (
    <StyledWrapper className={className} withScrubber={withScrubber}>
      <Slider value={progress * 100} step={0.1} onChange={onChange} />
    </StyledWrapper>
  );
}
export default ProgressBar;
