import React from 'react';
import { forwardRef, memo } from 'react';
const ListIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 66.154V20.769c0-1.02.4-1.999 1.113-2.72a3.778 3.778 0 0 1 2.688-1.126h4.77v1.538h-4.77c-.605 0-1.185.243-1.613.676a2.321 2.321 0 0 0-.668 1.632v45.385c0 .611.24 1.199.668 1.632a2.266 2.266 0 0 0 1.613.675h33.45c.606 0 1.186-.243 1.613-.675a2.32 2.32 0 0 0 .668-1.632V20.769c0-.613-.24-1.2-.668-1.632a2.266 2.266 0 0 0-1.612-.676h-5.11v-1.538h5.11a3.78 3.78 0 0 1 2.688 1.126 3.87 3.87 0 0 1 1.113 2.72v45.385c0 1.019-.4 1.998-1.113 2.72A3.778 3.778 0 0 1 56.252 70h-33.45a3.78 3.78 0 0 1-2.689-1.126A3.869 3.869 0 0 1 19 66.154Z"
      fill="#2D4459"
    />
    <path
      d="M51.143 16.286V19.429a2.359 2.359 0 0 1-2.357 2.357H29.928a2.356 2.356 0 0 1-2.357-2.357V16.286a2.359 2.359 0 0 1 2.357-2.357h5.5a3.93 3.93 0 0 1 5.893-3.403 3.93 3.93 0 0 1 1.965 3.403h5.5a2.356 2.356 0 0 1 2.357 2.357Z"
      fill="#F25E53"
    />
    <path
      d="M27.571 41.071h8.182m-8.182 6.786h22.5m-22.5 6.786h22.5m-22.5 6.786h18.41"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(ListIcon);
const Memo = memo(ForwardRef);
export default Memo;
