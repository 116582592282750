import React from 'react';
import { forwardRef, memo } from 'react';
const DashboardIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M62 19.8V10a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v9.8m59 0H3m59 0v33.4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V19.8"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.9 14.883a.983.983 0 1 0 0-1.966.983.983 0 0 0 0 1.966ZM15.783 14.883a.983.983 0 1 0 0-1.966.983.983 0 0 0 0 1.966ZM22.667 14.883a.983.983 0 1 0 0-1.966.983.983 0 0 0 0 1.966Z"
      fill="#F25E53"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={22} y={34} width={5} height={17} rx={2.5} fill="#F25E53" />
    <rect x={38} y={40} width={5} height={11} rx={2.5} fill="#F25E53" />
    <rect x={30} y={27} width={5} height={24} rx={2.5} fill="#F25E53" />
  </svg>
);
const ForwardRef = forwardRef(DashboardIcon);
const Memo = memo(ForwardRef);
export default Memo;
