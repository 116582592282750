import React from 'react';
import { forwardRef, memo } from 'react';
const SearchIcon = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" ref={ref} {...props}>
    <g stroke="#2D4459" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M9.167 15.833a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333ZM17.5 17.5l-3.625-3.625" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SearchIcon);
const Memo = memo(ForwardRef);
export default Memo;
