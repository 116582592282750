import React from 'react';
import { transparentize } from 'polished';
import styled from 'styled-components';
import P from '../../../common/P';
import { ParagraphVariants } from '../../../common/P/P';
import theme from '../../../../theme/theme';

const StyledWrapper = styled.div`
  padding: 32px;
  padding-top: 48px;
  background-color: ${transparentize(0.03, '#fff')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  filter: drop-shadow(0px 0px 64px rgba(0, 0, 0, 0.05));
  margin: auto;
  user-select: none;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: 347px;
    margin: unset;
    margin-left: ${({ rtl }) => (rtl ? 'auto' : 0)};
  }
`;
const StyledAvatar = styled.img`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  overflow: hidden;
  width: 64px;
  height: 64px;
  border-radius: 100px;
`;
const StyledText = styled(P)`
  color: ${theme.colors['Moonlit Ocean']};
`;
function TestimonialCard({ src, text, author, company, rtl, ...rest }) {
  return (
    <StyledWrapper {...rest} rtl={rtl}>
      <StyledAvatar src={src} />
      <StyledText variant={ParagraphVariants.SECONDARY}>{text}</StyledText>
      <StyledText>
        <b>{author},</b> {company}
      </StyledText>
    </StyledWrapper>
  );
}
export default TestimonialCard;
