import React from 'react';
import deepmerge from 'deepmerge';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import theme, { BREAKPOINTS } from '../../../../theme/theme';
import { CASE_STUDIES } from '../../../../data';
import SwiperSection from '../../common/SwiperSection';
import CaseStudyCard from './CaseStudyCard';

const StyledCaseStudiesSwiperSection = styled(SwiperSection)`
  margin-top: 72px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 100px;

    .text-wrapper,
    .navigation-wrapper {
      max-width: 258px;
      padding-right: 38px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    .text-wrapper,
    .navigation-wrapper {
      padding-right: 71px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    .text-wrapper,
    .navigation-wrapper {
      padding-right: 102px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    .text-wrapper,
    .navigation-wrapper {
      padding-right: 166px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 140px;

    .text-wrapper,
    .navigation-wrapper {
      max-width: 322px;
      padding-right: 94px;
    }

    .swiper {
      max-width: 807px;
    }
  }
`;
const caseStudySwiperConfig = {
  slidesPerView: 1.1,
  breakpoints: {
    [BREAKPOINTS['xs+']]: { slidesPerView: 1 },
    [BREAKPOINTS.sm]: { slidesPerView: 1.2 },
    [BREAKPOINTS['sm+']]: { slidesPerView: 1.5 },
    [BREAKPOINTS.md]: { slidesPerView: 1.8 },
    [BREAKPOINTS.xl]: { slidesPerView: 2 },
  },
};
function CaseStudySection() {
  return (
    <StyledCaseStudiesSwiperSection
      tagline="Case study"
      title="We work with all assets and project types."
      customSwiperConfig={(conf) => deepmerge(conf, caseStudySwiperConfig)}
    >
      {CASE_STUDIES.map((caseStudy, i) => (
        <SwiperSlide key={caseStudy.id}>
          <CaseStudyCard data-aos="fade-left" data-aos-delay={`${i * 100}`} {...caseStudy} />
        </SwiperSlide>
      ))}
    </StyledCaseStudiesSwiperSection>
  );
}
export default CaseStudySection;
