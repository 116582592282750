import React, { useRef } from 'react';
import styled from 'styled-components';
import BookADemoButton from '../../../common/BookADemoButton';
import config from '../../../../config';
import { LayoutMixin } from '../../../../theme';
import theme from '../../../../theme/theme';
import AnimatedSubtext, { StyledChar } from './AnimatedSubtext';
import ImagesDesktop from './ImagesDesktop';

const StyledWrapper = styled.div`
  overflow: hidden;
  background: ${theme.colors['Hero Gradient']};
`;
const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 762px;
  position: relative;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    ${LayoutMixin};
    flex-direction: row-reverse;
    justify-content: space-between;
    column-gap: 30px;
    min-height: unset;
    height: 574px;
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    padding-right: calc(${theme.paddings.container['sm+']} + 48px);
  }

  @media screen and (min-width: ${theme.breakpoints[1194]}) {
    padding-right: calc(${theme.paddings.container.md} + 80px);
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    height: 720px;
    padding-right: calc(${theme.paddings.container.xl} + 103px);
  }
`;
const StyledContainerText = styled.div`
  ${LayoutMixin};
  min-height: 500px;
  padding-top: 24px;
  padding-bottom: 62px;

  p {
    font-family: ${theme.fontFamily.Inter};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.Mindnight};
    margin: 16px 0 24px;
  }

  @media screen and (min-width: ${theme.breakpoints[375]}) {
    min-height: 410px;
  }

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    min-height: unset;
    max-width: unset;
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
      max-width: 440px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    p {
      max-width: 520px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    p {
      max-width: 440px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    p {
      font-size: 24px;
      line-height: 32px;
      margin: 24px 0 56px;
    }
  }
`;
const StyledTitle = styled.div`
  color: ${theme.colors['Moonlit Ocean']};

  h1,
  ${StyledChar} {
    font-weight: 300;
    font-size: 48px;
    line-height: 60px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    h1,
    ${StyledChar} {
      font-size: 72px;
      line-height: 90px;
    }
  }
`;
const StyledImagesContainer = styled.div`
  ${LayoutMixin};
  padding: unset !important;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    max-width: unset !important;
    margin: unset;
    margin-right: calc(-${theme.paddings.container['xs+']} - 66px);
    width: 313px;
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    margin-right: -${theme.paddings.container.sm};
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    margin-right: unset;
    justify-content: center;
    width: 392px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    width: 392px;
  }
`;
const StyledImageHero = styled.img`
  width: 196px;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: 100%;
  }
`;
const StyledCard = styled.div`
  margin-top: 95px;
  position: absolute;
  left: max(0px, calc(((100% - ${theme.breakpoints[375]}) / 2) + 30px));
  border-radius: 9.48148px;
  filter: drop-shadow(0px 0px 20.0715px rgba(22, 31, 38, 0.05));
  z-index: 1;
  width: 185px;
  height: 233.5px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: 229px;
    height: 296px;
    margin-top: 168px;
    left: unset;
    right: calc(${theme.paddings.container['xs+']} + 18px);
  }

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    right: calc(${theme.paddings.container.sm} + 84px);
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    display: none;
  }
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    display: none;
  }
`;
function HeroSection() {
  const textContainerRef = useRef(null);
  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledCard>
          <StyledImage
            data-aos="fade-left"
            src={`${config.CLOUDFLARE_FULL_URL}/48291058-932a-4a0b-e900-db7e26b87e00/public`}
          />
        </StyledCard>
        <StyledImagesContainer>
          <StyledImageHero
            src={`${config.CLOUDFLARE_FULL_URL}/d0ee210c-fc90-4e96-7db5-a38b0e0b3000/public`}
          />
        </StyledImagesContainer>

        <ImagesDesktop />

        <StyledContainerText ref={textContainerRef}>
          <StyledTitle>
            <h1>Easily Manage</h1>
            <AnimatedSubtext containerRef={textContainerRef} />
          </StyledTitle>
          <p>Banner provides the ideal workflow to make project delivery predictable and efficient</p>
          <BookADemoButton />
        </StyledContainerText>
      </StyledContentWrapper>
    </StyledWrapper>
  );
}
export default HeroSection;
