import React from 'react';
import { forwardRef, memo } from 'react';
const QuoteIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m4.6 52 16.293-6.006c2.333.526 4.716.789 7.107.784 14.387 0 26-9.313 26-20.89C54 14.314 42.387 5 28 5S2 14.313 2 25.889c0 5.831 3.033 11.14 7.887 14.97L4.6 52Z"
      fill="#F3F3F7"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={45} cy={47} r={13} fill="#F25E53" />
    <path
      d="M48.18 44.205a3.039 3.039 0 0 0-1.02-1.806 2.97 2.97 0 0 0-1.928-.716c-1.65 0-2.988 1.103-2.988 2.774s1.494 2.428 2.988 2.775c1.494.346 2.988 1.103 2.988 2.774 0 1.672-1.337 2.775-2.988 2.775a2.968 2.968 0 0 1-2.113-.886 3.046 3.046 0 0 1-.875-2.14M45.66 41.035v-1.913m0 16.22v-1.914"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(QuoteIcon);
const Memo = memo(ForwardRef);
export default Memo;
