import React from 'react';
import { forwardRef, memo } from 'react';
const DashboardSettingsIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.923 18.077a1.154 1.154 0 1 0 0-2.308 1.154 1.154 0 0 0 0 2.308ZM22 18.077a1.154 1.154 0 1 0 0-2.308 1.154 1.154 0 0 0 0 2.308ZM30.077 18.077a1.154 1.154 0 1 0 0-2.308 1.154 1.154 0 0 0 0 2.308Z"
      fill="#F25E53"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67 23.846V12a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v11.846m60 0H7m60 0v25.797M7 23.846V58.77a2 2 0 0 0 2 2h44.071"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.076 58.784a2 2 0 0 1 .722 2.717l-1.716 3.003a2 2 0 0 1-2.773.718l-.09-.054c-.73-.442-1.65-.358-2.355.125-.191.13-.383.254-.573.366-.883.522-1.727 1.458-1.727 2.484A1.857 1.857 0 0 1 62.707 70h-3.572c-1.104 0-2-.91-2-2.016 0-.973-.716-1.793-1.616-2.164a7.602 7.602 0 0 1-.971-.485c-.72-.426-1.615-.548-2.35-.147l-.13.072a2 2 0 0 1-2.689-.753l-1.73-2.986a2 2 0 0 1 .732-2.736l.098-.057c.736-.423 1.106-1.264 1.059-2.111-.01-.182-.016-.364-.017-.546a10.86 10.86 0 0 1 .02-.815c.05-.795-.3-1.584-.993-1.977a1.864 1.864 0 0 1-.7-2.546l1.818-3.18a1.94 1.94 0 0 1 2.658-.715c.713.413 1.597.309 2.312-.1a7.33 7.33 0 0 1 .858-.42c.91-.374 1.641-1.188 1.641-2.172 0-1.104.896-2.003 2-2.003h3.572c1.025 0 1.857.831 1.857 1.857s.848 1.948 1.733 2.466c.172.101.345.211.518.329.681.46 1.565.552 2.276.139a1.958 1.958 0 0 1 2.677.71l1.78 3.072a1.806 1.806 0 0 1-.734 2.511c-.708.366-1.078 1.152-1.028 1.947.016.268.023.536.02.804 0 .187-.006.373-.017.558-.047.841.315 1.677 1.041 2.104l.246.144Zm-7.583-2.713a4.643 4.643 0 1 1-9.286 0 4.643 4.643 0 0 1 9.286 0Z"
      fill="#F25E53"
    />
  </svg>
);
const ForwardRef = forwardRef(DashboardSettingsIcon);
const Memo = memo(ForwardRef);
export default Memo;
