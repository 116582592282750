import React from 'react';
import theme from '../../../theme/theme';
import styled, { css } from 'styled-components';
export var ParagraphVariants;
(function (ParagraphVariants) {
  ParagraphVariants['PRIMARY'] = 'primary';
  ParagraphVariants['SECONDARY'] = 'secondary';
  ParagraphVariants['TERTIARY'] = 'tertiary';
})(ParagraphVariants || (ParagraphVariants = {}));
export const VariantStyles = {
  [ParagraphVariants.PRIMARY]: css``,
  [ParagraphVariants.SECONDARY]: css`
    font-size: ${theme.fontSizes['16px']};
    line-height: ${theme.lineHeights['24px']};
  `,
  [ParagraphVariants.TERTIARY]: css`
    font-size: ${theme.fontSizes['24px']};
    line-height: ${theme.lineHeights['32px']};
  `,
};
const StyledParagraph = styled.p`
  ${({ variant }) => variant && VariantStyles[variant]}
`;
const P = (props) => <StyledParagraph {...props} />;
export default P;
