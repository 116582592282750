import { useEffect, useReducer } from 'react';

/**
 * Waits for React rerendering and `useEffect`(s) to finish before continuing. This is implemented by adding a
 * tiny rerender with almost no performance impact to the rendering queue before running your callback.
 */
// In the future, this can be used for request merging enhancements to `AppContext.api` and `useApi`.
export default function waitForUseEffect<T extends undefined | (() => any)>(callback: T) {
  return new Promise<T extends undefined ? void : ReturnType<Exclude<T, undefined>>>((resolve, reject) => {
    internalCallbackQueue.push(async () => {
      try {
        resolve(await callback?.());
      } catch (e) {
        reject(e);
      }
    });

    if (!rerenderTriggerRef.current) {
      throw new Error('<WaiterForUseEffect /> is no longer mounted');
    }
    rerenderTriggerRef.current();
  });
}

const rerenderTriggerRef = { current: null as null | (() => void) };
const internalCallbackQueue: (() => void)[] = [];

export function WaiterForUseEffect() {
  const triggerRerender = useReducer(() => ({}), {})[1];
  rerenderTriggerRef.current = triggerRerender;

  useEffect(() => {
    rerenderTriggerRef.current = triggerRerender; // just in case

    return () => {
      // only happens during dev mode hot reload
      if (rerenderTriggerRef.current === triggerRerender) {
        rerenderTriggerRef.current = null;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const callbacksNow = [...internalCallbackQueue];
    internalCallbackQueue.length = 0;
    callbacksNow.forEach((callbackWithCatch) => callbackWithCatch());
  });
  return null;
}
