import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Footer from '../Sections/common/Footer';
import LandingHeader from '../Sections/common/LandingHeader';
import ScrollToTop from '../common/ScrollToTop';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
function Layout({ children, className, header, footer, seo }) {
  return (
    <>
      <ScrollToTop />
      {header || <LandingHeader />}
      {seo && (
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:type" content={seo.ogType} />
          <meta property="og:url" content={seo.ogUrl} />
          <meta property="og:image" content={seo.ogImage} />
        </Helmet>
      )}
      <StyledWrapper className={className}>{children}</StyledWrapper>
      {footer || <Footer />}
    </>
  );
}
export default Layout;
