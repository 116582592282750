import React, { forwardRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import ReactPlayer from 'react-player/file';
import styled, { css } from 'styled-components';
import { CrossIcon, SolidPlayIcon } from '../../Icons';
import useVideoControls from '../../../hooks/useVideoControls';
import { ResetButton } from '../../../theme';
import TCP1 from '../../../assets/tourmalinePic.jpeg';
import theme from '../../../theme/theme';
import Controls from './Controls';

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${({ isFullScreen }) => (!isFullScreen ? '16px' : '0 !important')};

  &:hover .controls-wrapper {
    opacity: 0.9;
    transition-delay: 0s;
  }

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      video {
        object-fit: contain !important;
      }
    `}
`;
const StyledButton = styled.button`
  ${ResetButton};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledPlayButton = styled(StyledButton)`
  width: 44.8px;
  height: 44.8px;
  background: ${theme.colors.White};
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition:
    opacity 0.2s,
    transform 0.2s;

  > svg {
    width: 16.8px;
    height: 16.8px;
    margin-top: 1px;
    margin-left: 3px;
  }

  ${({ isPlaying }) =>
    isPlaying &&
    css`
      opacity: 0;
      cursor: unset;
      transform: translate(-50%, -50%) scale(1.2);
    `}

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    width: 84.8px;
    height: 84.8px;

    > svg {
      width: unset;
      height: unset;
      margin-top: 2px;
      margin-left: 6px;
    }
  }
`;
const StyledCloseButton = styled(StyledButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  filter: drop-shadow(0 0 20px ${theme.colors.Black});
  transition:
    transform 0.3s,
    opacity 0.3s,
    visibility 0.3s;

  > svg {
    width: 14.35px;
    height: 14.35px;
  }

  ${({ hideCloseButton }) =>
    hideCloseButton &&
    css`
      transform: translateY(-100%);
      opacity: 0;
      visibility: hidden;
    `}

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    top: 40px;
    right: 40px;

    > svg {
      width: unset;
      height: unset;
    }
  }
`;
const StyledControls = styled(Controls)`
  transition:
    transform 0.3s,
    opacity 0.3s,
    visibility 0.3s;

  ${({ hideControls }) =>
    hideControls &&
    css`
      transform: translateY(100%);
      opacity: 0 !important;
      visibility: hidden;
    `}
`;
function Video(
  { className, url, width, height, hideControls, onClickPlay, onClickClose, hideCloseButton = true, ...rest },
  ref,
) {
  const {
    containerRef,
    playerRef,
    controlsRef,
    closeButtonRef,
    controls,
    setControls,
    handleContainerClick,
    handleOnProgress,
    ...controlsHandlers
  } = useVideoControls({ onClickPlay });
  return (
    <StyledWrapper
      {...rest}
      ref={mergeRefs([containerRef, ...(ref ? [ref] : [])])}
      onClick={handleContainerClick}
      className={className}
      isFullScreen={controls.fullScreen}
    >
      <ReactPlayer
        ref={playerRef}
        url={url}
        width={controls.fullScreen ? '100%' : width}
        height={controls.fullScreen ? '100%' : height}
        onProgress={({ played }) => controls.playing && handleOnProgress(played)}
        progressInterval={10}
        volume={controls.volume}
        playing={controls.playing}
        muted={controls.muted}
        style={{ display: 'flex' }}
        config={{
          file: {
            attributes: {
              poster: TCP1,
            },
          },
        }}
      />
      <StyledCloseButton
        ref={closeButtonRef}
        onClick={() => onClickClose?.({ controls, setControls, ...controlsHandlers })}
        hideCloseButton={hideCloseButton}
      >
        <CrossIcon />
      </StyledCloseButton>
      <StyledPlayButton isPlaying={controls.playing}>
        <SolidPlayIcon />
      </StyledPlayButton>
      <StyledControls
        {...controls}
        {...controlsHandlers}
        ref={controlsRef}
        currentTime={playerRef.current?.getCurrentTime() || 0}
        totalDuration={playerRef.current?.getDuration() || 0}
        hideControls={hideControls}
      />
    </StyledWrapper>
  );
}
export default forwardRef(Video);
