import React from 'react';
import { forwardRef, memo } from 'react';
const DashboardBarIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M70 27.692V17a2 2 0 0 0-2-2H12a2 2 0 0 0-2 2v10.692m60 0H10m60 0V63.77a2 2 0 0 1-2 2H12a2 2 0 0 1-2-2V27.692"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM23 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM30 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#F25E53"
      stroke="#F25E53"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={29} y={42.441} width={5.085} height={17.288} rx={2.542} fill="#F25E53" />
    <rect x={45.271} y={48.542} width={5.085} height={11.186} rx={2.542} fill="#F25E53" />
    <rect x={37.136} y={35.322} width={5.085} height={24.407} rx={2.542} fill="#F25E53" />
  </svg>
);
const ForwardRef = forwardRef(DashboardBarIcon);
const Memo = memo(ForwardRef);
export default Memo;
