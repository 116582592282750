import React from 'react';
import styled from 'styled-components';
import BookADemoButton from '../../../common/BookADemoButton';
import { LayoutMixin } from '../../../../theme';
import theme from '../../../../theme/theme';

const StyledWrapper = styled.div`
  ${LayoutMixin};
  max-width: unset;
  padding: unset;
  box-shadow: ${theme.colors['Wrapper Box Shadow']};
  background-color: ${theme.colors.White};
  min-height: 224px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 72px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: fit-content;
    padding: unset;
    border-radius: 8px;
    z-index: 1;
    position: absolute;
    top: 0;
    margin-top: 0;
    right: 0;
    left: 0;
    padding: 48px 64px;

    &[data-aos='fade-up'] {
      transform: translate3d(0, calc(100px + 100px), 0);

      &.aos-animate {
        transform: translate3d(0px, -50%, 0);
      }
    }
  }

  @media screen and (min-width: ${theme.breakpoints[1024]}) {
    padding: 64px 104px;
  }
`;
const StyledInnerWrapper = styled.div`
  ${LayoutMixin};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    padding: unset;
    flex-direction: row;
    max-width: unset;
    gap: 64px;
  }
`;
const Styledtext = styled.h1`
  color: ${theme.colors['Moonlit Ocean']};
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  gap: 32px;
  text-align: center;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    display: flex;
    width: 299px;
  }
`;
function OurToolsSection() {
  return (
    <StyledWrapper data-aos="fade-up">
      <StyledInnerWrapper>
        <Styledtext data-aos="fade-up" data-aos-delay="100">
          Upgrade your tools. Keep your process.
        </Styledtext>
        <BookADemoButton data-aos="fade-up" data-aos-delay="200" />
      </StyledInnerWrapper>
    </StyledWrapper>
  );
}
export default OurToolsSection;
