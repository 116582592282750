/* eslint-disable func-names */
/* eslint-disable spaced-comment */
import { Button, DialogActions, DialogContent, withStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import {
  stylesDefaultBlueButton,
  stylesDefaultRedButton,
  stylesDefaultSecondaryButton,
} from '../../util/styles';
import BDialog, { BDialogTitle } from '../util/BDialog';
import css from './AppDialogs.module.css';

const CancelButton = withStyles({
  // TODO We could probably use stylesDefaultSecondaryButton instead,
  // but wondering if stylesDefaultSecondaryButton should be slimmed down or is it optimal?
  root: { textTransform: 'none', fontSize: '14px' },
})(Button);
const AddButton = withStyles({
  root: {
    ...stylesDefaultBlueButton,
    fontSize: '14px',
    marginLeft: '10px',
  },
})(Button);

const SecondaryButton = withStyles({
  root: {
    ...stylesDefaultSecondaryButton,
    fontSize: '14px',
    marginLeft: '10px',
  },
})(Button);

const DangerButton = withStyles({
  root: {
    ...stylesDefaultRedButton,
    fontSize: '14px',
    marginLeft: '10px',
  },
})(Button);

type Ask<Answers extends readonly string[]> = Readonly<
  {
    title: ReactNode;
    text: ReactNode;
    answersReturn: Answers;
    answersType?: string[];
    type?: 'danger' | 'danger with delay';
    alignment?: string;
  } & (
    | {
        cancelAsNull?: never;
        noCancelButtonJustClickBg: true;
      }
    | {
        cancelAsNull: ReactNode;
        noCancelButtonJustClickBg?: false;
      }
  )
>;

export type DialogFn = ReturnType<typeof useAppDialogFn>['dialog'];

type DialogFnRef = ReturnType<typeof useAppDialogFn>['dialogFnRef'];

export function useAppDialogFn() {
  type DialogImplFn = <Answers extends readonly string[]>(
    ask: Ask<Answers>,
  ) => Promise<Answers[number] | null>;
  type DialogProxyFn = <Answers extends readonly string[]>(
    ask: Ask<Answers>,
  ) => Promise<Answers[number] | null>;
  const dialogFnRef = useRef<DialogImplFn | null>(
    /*
     */ null,
  );

  return useMemo(() => {
    /**
     * This function memoized/constant `useAppContext().dialog`.
     * It is the proxy to `dialogFnRef.current` which is not initialized until `<AppDialogs>` is mounted in the component tree.
     */
    const dialog: DialogProxyFn = async (ask) => {
      if (dialogFnRef.current) return await dialogFnRef.current(ask);
      else throw new Error('This race condition is impossible to happen.');
    };

    return { dialog, dialogFnRef };
  }, []);
}

export default function AppDialogs(props: { dialogImplementationRef: DialogFnRef }) {
  const [dialogs, setDialogs] = useState(
    [] as (Ask<readonly string[]> & { resolve: (answer: string | null) => void })[],
  );

  props.dialogImplementationRef.current = (o) =>
    new Promise((resolve) => {
      setDialogs([...dialogs, { ...o, resolve }]);
    });

  /** First queued dialog (in case multiple dialogs have been requested) */
  const firstInQueue = dialogs[0];

  // even if queue of dialogs is cleared, the last rendered dialog must still be rendered
  // for smooth animation to open={false} without immediately unmounting it
  const [lastRendered, setLastRenderedDialog] = useState<typeof firstInQueue | null>(null);
  useEffect(() => {
    if (dialogs.length !== 0) {
      setLastRenderedDialog(firstInQueue);
    }
  }, [firstInQueue]);

  const ask = lastRendered;

  const ButtonComponent =
    ask?.type === 'danger' || ask?.type === 'danger with delay' ? DangerButton : AddButton;

  const [delayReached, setDelayReached] = useState(null as null | typeof ask);
  const disableUntilDelay = ask?.type === 'danger with delay';
  const disabledBecauseDelay = disableUntilDelay && delayReached !== ask;

  useEffect(() => {
    if (disableUntilDelay) {
      setTimeout(function () {
        setDelayReached(ask);
      }, 1000);
    }
  }, [ask]);

  const concludedSinceLastRerender = useRef(false);
  concludedSinceLastRerender.current = false;

  return (
    <BDialog
      open={dialogs.length !== 0 && firstInQueue === lastRendered}
      onClose={() => conclude(null)}
      aria-describedby="alert-dialog-description"
      style={{
        textAlign: 'left', // override body { text-align: center; } from app.css
      }}
    >
      {ask ? (
        <>
          <BDialogTitle>{ask.title}</BDialogTitle>
          <DialogContent id="alert-dialog-description">
            <div className={css.imitateDialogContentText}>{ask.text}</div>
          </DialogContent>
          <DialogActions style={{ justifyContent: ask?.alignment ?? 'flex-end' }}>
            {!ask.noCancelButtonJustClickBg && (
              <CancelButton
                variant="outlined"
                onClick={() => conclude(null)}
                data-cy="AppDialogs-button-cancel"
              >
                {ask.cancelAsNull || 'Cancel'}
              </CancelButton>
            )}
            {ask.answersReturn.map((answer, i) => {
              const type = ask.answersType?.[i];
              const AnswerButton = type && type === 'secondary' ? SecondaryButton : ButtonComponent;
              return (
                <AnswerButton
                  key={answer}
                  onClick={() => conclude(answer)}
                  data-cy="AppDialogs-button-primary"
                  disabled={disabledBecauseDelay}
                >
                  {answer}
                </AnswerButton>
              );
            })}
          </DialogActions>
        </>
      ) : null}
    </BDialog>
  );

  function conclude(answer: Exclude<typeof ask, null>['answersReturn'][number] | null) {
    if (concludedSinceLastRerender.current) return; // occurs if dblclick is faster than React rerender (happens in Cypress tests)
    concludedSinceLastRerender.current = true;
    setDialogs(dialogs.slice(1));
    ask!.resolve(answer);
  }
}
