import React from 'react';
import deepmerge from 'deepmerge';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import theme, { BREAKPOINTS } from '../../../../theme/theme';
import { BENEFITS } from '../../../../data';
import SwiperSection from '../../common/SwiperSection';
import BenefitCard from './BenefitCard';

const StyledBenefitsSwiperSection = styled(SwiperSection)`
  margin-top: 72px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 100px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    margin-top: 80px;
  }
`;
const benefitsSwiperConfig = {
  slidesPerView: 1.1,
  breakpoints: {
    [BREAKPOINTS['xs+']]: { slidesPerView: 1.3 },
    [BREAKPOINTS.sm]: { slidesPerView: 1.5 },
    [BREAKPOINTS['sm+']]: { slidesPerView: 2 },
    [BREAKPOINTS.md]: { slidesPerView: 2.4 },
    [BREAKPOINTS.xl]: { slidesPerView: 2.26 },
  },
};
function BenefitSection() {
  return (
    <StyledBenefitsSwiperSection
      tagline="Benefits"
      title="Execute on your projects."
      customSwiperConfig={(conf) => deepmerge(conf, benefitsSwiperConfig)}
    >
      {BENEFITS.map((benefit, i) => (
        <SwiperSlide key={benefit.id}>
          <BenefitCard data-aos="fade-left" data-aos-delay={`${i * 100}`} {...benefit} />
        </SwiperSlide>
      ))}
    </StyledBenefitsSwiperSection>
  );
}
export default BenefitSection;
