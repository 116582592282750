import React from 'react';
import { LongArrow } from '../../../../assets';
import { ResetButton } from '../../../../theme';
import theme from '../../../../theme/theme';
import styled, { css } from 'styled-components';
const StyledWrapper = styled.div`
  max-width: 288px;
  background: unset;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    max-width: 264px;
  }

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    max-width: 288px;
  }
`;
const StyledButton = styled.button`
  ${ResetButton};
  cursor: pointer;

  &:disabled path {
    fill: ${theme.colors['Moonlit Ocean']};
    opacity: 0.2;
  }
`;
const StyledLongArrow = styled(LongArrow)`
  path {
    transition:
      fill 0.3s,
      opacity 0.3s;
  }

  ${({ $reverse }) =>
    $reverse &&
    css`
      transform: rotate(180deg);
    `};
`;
const CustomNavigation = ({ swiper, slideCount, currentSlide, className }) => {
  if (!swiper) return null;
  return (
    <StyledWrapper data-aos="zoom-in-right" data-aos-offset="-5" className={className}>
      <StyledButton
        disabled={!swiper.loopedSlides ? swiper.isBeginning : currentSlide === 1}
        onClick={() => swiper.slidePrev()}
        aria-label="back slide"
      >
        <StyledLongArrow $reverse />
      </StyledButton>
      {currentSlide}/{slideCount || 1}
      <StyledButton
        disabled={!swiper.loopedSlides ? swiper.isEnd : currentSlide === slideCount}
        onClick={() => swiper.slideNext()}
        aria-label="next slide"
      >
        <StyledLongArrow />
      </StyledButton>
    </StyledWrapper>
  );
};
export default CustomNavigation;
