import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme/theme';
import Tagline from '../../../common/Tagline';
import { LayoutMixin } from '../../../../theme';

const StyledWrapper = styled.div`
  overflow: hidden;
`;
const StyledInnerWrapper = styled.div`
  ${LayoutMixin};
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  position: relative;
`;
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    max-width: 40%;
    flex-shrink: 0;
  }
`;
const StyledTitle = styled.h2`
  color: ${theme.colors['Moonlit Ocean']};
`;
const StyledGrid = styled.div`
  width: 100%;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
`;
function IconsGrid({ tagline, title, className, children }) {
  return (
    <StyledWrapper className={className}>
      <StyledInnerWrapper>
        <StyledTextWrapper>
          <Tagline data-aos="fade-up" data-aos-delay="100" text={tagline} />
          {title && <StyledTitle data-aos="fade-up">{title}</StyledTitle>}
        </StyledTextWrapper>
        <StyledGrid className="inner-icons-grid">{children}</StyledGrid>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
}
export default IconsGrid;
