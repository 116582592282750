import { Dialog, DialogProps, DialogTitle, DialogTitleProps, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { NotUndefined } from '../../../common/util/type-utils';
import { GetSet, getset, joinClasses } from '../../util/react-utils';
import bmui from '../css/util/bmui.module.css';
import css from './BDialog.module.css';

const DEV = process.env.NODE_ENV === 'development';

const clickedXReason = 'clickedX';

type Props = {
  open: boolean;
  /** has third `reason` `"clickedX"` which only happens if you have `<BDialogTitle xIcon />` */
  onClose: (
    event,
    reason: Parameters<NotUndefined<DialogProps['onClose']>>[1] | typeof clickedXReason,
  ) => void;
  /** basically we are undeprecating what material ui had deprecated 😆, but we are implementing it in an undeprecated way */
  disableBackdropClick?: boolean;
  /**
   * Includes the buttons and title in the scrollable area rather than positioning them sticky to the top and bottom.
   * This is useful for pseudo-popover elements like `<CheckBoxSearchB>` which are bounded to the maximum size of their scrollable parent.
   */
  largerScrollArea?: boolean;
} & Omit<DialogProps, 'disableBackdropClick' | 'onClose'>;

/**
 * B is for Banner!
 *
 * Features:
 *
 * - Easy to have X close button in top right corner if you have `<BDialogTitle xIcon />` nested inside
 * - automatically sets `aria-labelledby` with `DialogTitle` so you don't have to
 * - provides an undeprecated `disableBackdropClick` option you can access
 *
 * @example
 * <BDialog
 *   open={open}
 *   onClose={() => setOpen(false)}
 *   disableBackdropClick={...}
 * >
 *   <BDialogTitle xIcon={...}>
 *     ...
 *   </BDialogTitle>
 *   <DialogContent>
 *     ...
 *   </DialogContent>
 *   <DialogActions>
 *     ...
 *   </DialogActions>
 * </BDialog>
 */
export default function BDialog(props: Props) {
  const { disableBackdropClick, children, onClose, largerScrollArea, ...rest } = props;

  const hasBDialogTitle = DEV ? getset(useState(false)) : null;

  return (
    /* eslint-disable-next-line react/jsx-no-constructed-context-values */ // scope too small to matter
    <BDialogContext.Provider value={{ onClose, hasBDialogTitle }}>
      <Dialog
        aria-labelledby="DialogTitle"
        onClose={(event, reason) => {
          if (reason === 'backdropClick' && disableBackdropClick) return;
          if (onClose) onClose(event, reason);
        }}
        {...rest}
        style={{
          textAlign: 'left', // override body { text-align: center; } from app.css
          ...rest.style,
        }}
        className={joinClasses([
          css.BDialog,
          bmui.bmuiScope, // bmuiScope added primarily for white-space: pre-wrap but also it's generally good practice
          rest.className,
          css.largerScrollArea,
        ])}
      >
        {hasBDialogTitle && !hasBDialogTitle.val && rest.open && (
          <div className="inline-whoops">
            [DEV] Please use <code>&lt;BDialogTitle&gt;</code> when using <code>&lt;BDialog&gt;</code>
          </div>
        )}
        {children}
      </Dialog>
    </BDialogContext.Provider>
  );
}

const BDialogContext = React.createContext(
  {} as {
    onClose: Props['onClose'];
    hasBDialogTitle: GetSet<boolean> | null;
  },
);

export const BDialogTitle = React.memo(_BDialogTitle);
function _BDialogTitle(props: { xIcon?: boolean } & DialogTitleProps) {
  const { xIcon, children, ...rest } = props;

  const { onClose, hasBDialogTitle } = useContext(BDialogContext);

  if (hasBDialogTitle)
    useLayoutEffect(() => {
      hasBDialogTitle.set(true);
      return () => hasBDialogTitle.set(false);
    }, []);

  return (
    <DialogTitle id="DialogTitle" {...rest}>
      {xIcon ? (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {children}
          {/*
          we could consider moving `<IconButton>` to be a sibling of `<DialogTitle>` instead of child,
          but then we'd have to override automatic `<DialogTitle>` padding from MUI
        */}
          <IconButton
            aria-label="close"
            onClick={(event) => onClose(event, clickedXReason)}
            style={{ margin: '-10px -10px 0' }}
            data-cy="b-dialog-close"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      ) : (
        children
      )}
    </DialogTitle>
  );
}
