import { useCallback, useState } from 'react';

const usePreventScrollStore = () => {
  const [preventing, setPreventing] = useState({});
  const handleSetPreventing = useCallback(
    (id, value) => setPreventing((prev) => ({ ...prev, [id]: value })),
    [],
  );
  return { preventing, handleSetPreventing };
};
export default usePreventScrollStore;
