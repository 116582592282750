import React from 'react';
import { forwardRef, memo } from 'react';
const BubbleIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m13.6 59 16.293-6.006c2.333.526 4.716.789 7.107.784 14.387 0 26-9.313 26-20.89C63 21.314 51.387 12 37 12s-26 9.313-26 20.889c0 5.831 3.033 11.14 7.887 14.97L13.6 59Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={54.93} cy={54.93} r={13.93} fill="#F25E53" />
    <path
      d="M57.935 52.083a3.04 3.04 0 0 0-1.018-1.806 2.968 2.968 0 0 0-1.93-.716c-1.65 0-2.987 1.103-2.987 2.774s1.494 2.429 2.988 2.775c1.494.346 2.988 1.103 2.988 2.775 0 1.671-1.338 2.774-2.988 2.774a2.97 2.97 0 0 1-2.113-.886 3.047 3.047 0 0 1-.875-2.14M55.415 48.913V47m0 16.22v-1.914"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(BubbleIcon);
const Memo = memo(ForwardRef);
export default Memo;
