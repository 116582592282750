const { Result } = require('./result');
const Log = require('./log');

const PERMISSION_READ = 'read';
const PERMISSION_WRITE = 'write';

function isValidPermission(permission) {
  return PERMISSION_READ === permission || PERMISSION_WRITE === permission;
}

function getManagerID(manager) {
  return typeof manager === 'string' ? manager : typeof manager === 'object' ? manager._id?.toString() : null;
}

function getPermissions(container) {
  return container.permissions;
}

// This is likely a programming error.
function warnThenRespond(container, permission, manager, reason) {
  Log.warn(
    {
      permissionSet: getPermissions(container),
      permission,
      managerID: getManagerID(manager),
    },
    reason,
  );

  return Result.err(reason);
}

function has(container, permission, manager) {
  if (!isValidPermission(permission)) {
    return warnThenRespond(container, permission, manager, 'invalid_permission');
  }

  const managerID = getManagerID(manager);

  if (!managerID) {
    return Result.err('invalid_manager');
  }

  const permissions = getPermissions(container);

  if (!permissions) {
    return warnThenRespond(container, permission, manager, 'invalid_permissions_container');
  }

  if (!permissions[managerID].includes(permission)) {
    return Result.err('not_authorized');
  }

  return Result.ok(managerID);
}

const hasManagerPermissions = (auth0User) =>
  auth0User && auth0User['https://withbanner.com/permissions'].includes('read:manager');

module.exports = {
  READ: PERMISSION_READ,
  WRITE: PERMISSION_WRITE,
  has,
  hasManagerPermissions,
};
