import React from 'react';
import { forwardRef, memo } from 'react';
const MoneyCheckIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M37 64c14.36 0 26-11.64 26-26S51.36 12 37 12 11 23.64 11 38s11.64 26 26 26Z"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.918 31.227a6.351 6.351 0 0 0-2.045-3.744A5.853 5.853 0 0 0 37 26c-3.314 0-6 2.286-6 5.75 0 3.462 3 5.032 6 5.75 3 .717 6 2.285 6 5.75 0 3.464-2.686 5.75-6 5.75a5.87 5.87 0 0 1-4.243-1.837A6.419 6.419 0 0 1 31 42.728M37 25.775V22m0 32v-3.776"
      stroke="#2D4459"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.93 68.86c7.693 0 13.93-6.237 13.93-13.93S64.623 41 56.93 41 43 47.237 43 54.93s6.237 13.93 13.93 13.93Z"
      fill="#F25E53"
    />
    <path
      d="m51.682 55.084 3.751 3.841 8.2-8.536"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(MoneyCheckIcon);
const Memo = memo(ForwardRef);
export default Memo;
